const ImageCarouselMixin = {
  methods: {
    carouselImageReady() {
      let slides = this.swiper.slides;
      let activeIndex = this.swiper.activeIndex;

      let slide = slides[activeIndex];

      // console.log('ImageCarouselMixin',
      //   '@ carouselImageReady',
      //   'slide',
      //   slide,
      //   'slide.offsetHeight',
      //   slide.offsetHeight);
    },
    paginationClick(action) {
      let slides = this.swiper.slides;
      let slidesCount = slides.length;
      let activeIndex = this.swiper.activeIndex;
      let nextIndex = activeIndex;

      if (action == "prev") {
        // prev item
        nextIndex -= 1;
      }
      else {
        // next item
        nextIndex += 1;
      }
      if (nextIndex == -1) {
        nextIndex = slidesCount - 1;
      }
      else if (nextIndex == slidesCount) {
        nextIndex = 0;
      }

      // console.log(
      //   "paginationClick",
      //   "this.swiperName",
      //   this.swiperName,
      //   "this.swiper.slides",
      //   this.swiper.slides,
      //   "this.swiper.activeIndex",
      //   this.swiper.activeIndex,
      //   "(slidesCount - 1)",
      //   (slidesCount - 1),
      //   "action",
      //   action
      // );

      this.swiper.slideToLoop(nextIndex);
    },
  }
}

module.exports = ImageCarouselMixin