<template>
  <div class="container my-container">
    <vue-inet-content
      :api_name="'Film-Video-Play'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
//const vue = require("vue");
import vue from "vue";
const _ = require("lodash");
const moment = require("moment");

const api = require("@/api");
const config = require("@/config");
const { LoadingMixin } = require("@/mixins");

export default {
  data() {
    return {
      metadata: {
        channel: this.channel,
        media: null,
        year: this.year,
        month: this.month,
        day: this.day,
        section: this.section,
        status_code: 0,
        data: "",
        result_number: 0,
        backToMonthList: this.backToMonthList,
        gotoSection: this.gotoSection,
        getMediaSrc: this.getMediaSrc,
        rightClick: this.rightClick,
      },
      metadata_ready: false,
    };
  },
  //mixins: [LoadingMixin],
  computed: {
    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
    section() {
      if (_.has(this.querys, "section")) {
        return this.querys.section;
      }
      return "1";
    },
    monthLink() {
      // var path = this.$router.resolve({
      //   name: "Film3",
      //   params: { channel: this.channel, year: this.year, month: this.month },
      // }).href;

      // return path;
      return {
        name: "Film3",
        params: { channel: this.channel, year: this.year, month: this.month },
      };
    },
    year() {
      if (_.has(this.routeParams, "year")) {
        return parseInt(this.routeParams["year"]);
      }
      return parseInt(moment().format("YYYY"));
    },
    month() {
      if (_.has(this.routeParams, "month")) {
        return parseInt(this.routeParams["month"]);
      }
      return parseInt(moment().format("MM"));
    },
    day() {
      if (_.has(this.routeParams, "day")) {
        return parseInt(this.routeParams["day"]);
      }
      return parseInt(moment().format("DD"));
    },
    verifyToken() {
      var value = vue.$sessionStorage.get("jwt-ot-token", null);

      return value;
    },
  },
  methods: {
    rightClick($event) {
      $event.preventDefault();
      $event.stopPropagation();

      return false;
    },
    backToMonthList($event) {
      this.$emit("redirectTo", this.monthLink);
    },
    getMediaSrc(media, section) {
      if (_.has(media, `media_section${section}`)) {
        return media[`media_section${section}`];
      }
      return "";
    },
    gotoSection(channel, section) {
      let routerItem = {
        name: "Film4",
        params: channel,
        query: { section },
      };

      var path = this.$router.resolve(routerItem).href;

      location.href = path;

      // this.$router.push({
      //   name: "FreeFilmChannel",
      //   params: { channel: this.channel },
      //   query: { section },
      // });

      //this.$emit("redirectTo", routerItem);
    },
  },
  mounted() {
    let _this = this;
    let payload = {};
    if (_.has(this.querys, "token")) {
      api.VerifyOTToken({ token: this.querys.token }).then((response) => {
        if (response.status_code == 200) {
          // set verify code
          vue.$sessionStorage.set("jwt-ot-token", response.result["data"]);

          var props = _this.$router.resolve({
            name: "Film4",
            params: {
              channel: _this.channel,
              year: _this.year,
              month: _this.month,
              day: _this.day,
            },
          });
          var url = props.href;
          location.href = url;
        }
      });
      return;
    }
    this.showLoading("get-media");
    api
      .GetMedia(this.channel, this.year, this.month, this.day, payload)
      .then((response) => {
        _this.metadata.status_code = response.status_code;

        if (response.status_code == 200) {
          _this.metadata.media = response.result;
          _this.metadata.channel = this.channel;
          _this.metadata.section = this.section;
          _this.metadata.monthLink = this.monthLink;
          _this.metadata.year = this.year;
          _this.metadata.month = this.month;
          _this.metadata.day = this.day;
        } else {
          _this.metadata.data = response.result.data;
          _this.metadata.result_number = response.result.result_number;
        }
        _this.metadata_ready = true;

        this.hideLoading("get-media");
      });
  },
};
</script>
