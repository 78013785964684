<template>
  <div class="container my-1th-container">
    <vue-inet-content
      :api_name="'Member-Register-Notice'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      metadata: {
        terms_confirmed: false,
        next: this.next,
      },
      metadata_ready: true,
    };
  },
  methods: {
    next() {
      // console.log(
      //   "MemberRegister.vue",
      //   "call next()",
      //   this.metadata,
      //   this.metadata.hasOwnProperty("__ob__"),
      //   this.metadata.getValue,
      //   this.get(this.metadata, "terms_confirmed")
      // );

      if (this.get(this.metadata, "terms_confirmed") == true) {
        this.$router.replace({ name: "MemberRegisterForm" });
      }
    },
  },
};
</script>
