const SweetAlert2Mixin = {
  methods: {
    showConfirm: function (text) {
      return new Promise((resolve) => {
        this.$swal({
          text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "確定要修改",
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#888",
        })
          .then(function (result) {
            resolve(result.isConfirmed);
          })
      })
    },
  }
}

module.exports = SweetAlert2Mixin