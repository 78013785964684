<template>
  <div
    class="container my-1th-container my-container"
    v-body-classname-remove="'primary-body'"
  >
    <vue-inet-content :api_name="'Member-Register-Done'" />
  </div>
</template>
<script>
export default {};
</script>
