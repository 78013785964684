<template> </template>
<script>
export default {
  methods: {
    redirectToIndex() {
      //this.$router.push({ name: "Index" });
      this.$emit("redirectTo", { name: "Index" });
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$sessionStorage.remove("jwt-token");
      this.$localStorage.remove("jwt-token");

      this.$sessionStorage.remove("mock-jwt-token");
      this.$localStorage.remove("mock-jwt-token");
    }

    this.$sessionStorage.remove("shopping-cart");

    this.redirectToIndex();
  },
};
</script>
