module.exports = {
  setIsLoading({ commit }, isloading) {
    commit('SetIsLoading', { isloading })
  },

  addToShoppingCart({ commit }, product) {
    commit('AddToShoppingCart', { product })
  },

  setRegisterEmail({ commit }, email) {
    commit('SetRegisterEmail', { email })
  },

  setOrderMonths({ commit }, months) {
    commit('SetOrderMonths', { months })
  }
}