require("@babel/polyfill");

const gt = require("just-get-json");

const options = {
  "config-online-host": "https://digichannel.go2school.com.tw",
  "config-online-url": "/config/config.json?_=6e81dd8e-0e39-4277-8a46-24bdf4183ffe",
};
var request = options["config-online-host"] + options["config-online-url"];
var result = gt(request);
module.exports = result;

// const fetchConfig = function (host, url, callback) {
//   return new Promise(function (resolve) {
//     let axios_config = {
//       method: 'get',
//       baseURL: host,
//       url,
//       responseType: 'json',
//       responseEncoding: 'utf8',
//     };

//     axios(axios_config)
//       .then(function (response) {
//         debugger;
//         resolve(response);
//         callback();
//       })
//       .catch(function (error) {
//         throw error;
//       });
//   });
// };

// module.exports = {
//   "fetch": function () {
//     var host = this["config-online-host"];
//     var url = this["config-online-url"];
//     var returned = false;
//     var result = {};

//     // fetchConfig(host, url, function (response) {
//     //     returned = true;
//     //     //result = response;
//     //     return response;
//     // });
//     const callback = function (response) {
//       returned = true;

//       if (response.data) {
//         result = response.data
//       }
//     };

//     var arrAsync = [
//       // fetchConfig.bind(null, host, url, callback)
//       fetchConfig.bind(null, host, url)
//     ];

//     result = ats(arrAsync);
//     debugger;
//     console.log("config.js", "result :", result);

//     return result;
//   },
//   "config-online-host": "https://beta.digichannel.go2school.com.tw",
//   "config-online-url": "/config/config.json",
//   "loadingTimeout": 15, // loading effect max show time in seconds.
//   "default-channel": "letstalk",
//   "inet-host": "https://inet.scenet.net",
//   "inet-api-host": "https://inet.scenet.net/service/api",
//   // prod
//   "digichannel-api-host": "https://digichannel.go2school.com.tw/api",
//   // dev
//   //"digichannel-api-host": "https://localhost:44336",
//   "inet-api": {
//     "TopHeaderMenu": "/board/1000000003000002/B20201016000001",
//     "HeaderMenu": "/board/1000000003000002/B20201016000002",
//     "ChildPageMenu": "/board/1000000003000002/B20201028000001",
//     "ChildPageMenu-Member": "/board/1000000003000002/B20201109000001",
//     "IndexSliderImage": "/blocks/1000000003000004",
//     "NewsPost": "/blocks/1000000003000005",
//     "QA": "/blocks/1000000003000006",
//     "IndexSliderImage2": "/blocks/1000000003000007",
//     "Footer": "/board/1000000003000002/B20201015000001",
//     "Product-LetsTalk": "/board/1000000003000002/B20201028000002",
//     "Product-StudioClassroom": "/board/1000000003000002/B20201028000003",
//     "Member-Login": "/board/1000000003000002/B20201029000001",
//     "Member-Register-Notice": "/board/1000000003000002/B20201029000002",
//     "Member-Register-Form": "/board/1000000003000002/B20201029000003",
//     "Member-Register-Verify-Email": "/board/1000000003000002/B20201119000001",
//     "Member-Register-Done": "/board/1000000003000002/B20201029000004",
//     "Member-Forget-Password": "/board/1000000003000002/B20201029000014",
//     "Member-Forget-Password-OK": "/board/1000000003000002/B20201029000015",
//     "Member-Center": "/board/1000000003000002/B20201109000002",
//     "TryFilm": "/board/1000000003000002/B20201029000005",
//     "Shop": "/board/1000000003000002/B20201029000006",
//     "Shop-Cart": "/board/1000000003000002/B20201029000007",
//     "Shop-Payment": "/board/1000000003000002/B20201029000008",
//     "Shop-Confirm": "/board/1000000003000002/B20201029000009",
//     "Film-Channel-Switch": "/board/1000000003000002/B20201029000010",
//     "Film-Month-List": "/board/1000000003000002/B20201029000011",
//     "Film-Video-List": "/board/1000000003000002/B20201029000012",
//     "Film-Video-Play": "/board/1000000003000002/B20201029000013",
//     "Products": "/blocks/1000000003000008"
//   },
//   "digichannel-api": {
//     "ShopItems": { "block_id": "1000000003000008" }
//   }
// }