<template>
  <span v-html="content" v-style="`${inlineStyle}`"></span>
</template>
<script>
const _ = require("lodash");
const api = require("../api");
const config = require("../config");

import { WatchMixin } from "../mixins";

export default {
  data() {
    return { content: "", customCss: "" };
  },
  props: ["api_name"],
  methods: {
    async loadFromApi(apiName) {
      //console.log(apiName);
      if (apiName && apiName !== "") {
        let url = config["inet-api"][apiName];
        let result = await api.GetBoardContent(url);
        if (result && result["iBoard"]) {
          let board = result["iBoard"];
          this.content = board["ContentC"];
          if (board["CustomCSS"]) {
            this.customCss = board["CustomCSS"];
          }
        }
      }
    },
  },
  computed: {
    inlineStyle() {
      return this.customCss;
    },
  },
  mounted() {
    let _this = this;
    let checkCondition = function() {
      return _.isString(_this.api_name) && _this.api_name != "";
    };
    this.watch(checkCondition, 100, 3000)
      .then(function() {
        _this.loadFromApi(_this.api_name);
      })
      .catch(function() {
        console.log("iNetContent.vue", "watch #1", "error");
      });
  },
  mixins: [WatchMixin],
};
</script>
