var _error_dialog_timeout_id = 0;

const GlobalMixin = {
  // props: {
  //   isLoadingDone: Boolean
  // },
  data() {
    return {
      renderComponent: true,
    }
  },
  computed: {

  },
  methods: {
    forceUpdate() {
      // console.log("global-mixin",
      //   "forceUpdate",
      //   "this :",
      //   this
      // )
      //this.$forceUpdate();
      this.renderComponent = false;
      // If you like promises better you can
      // also use nextTick this way
      this.$nextTick().then(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    showBackendError() {
      let _this = this
      var showErrorSwal = function () {
        _this.$swal({
          icon: "error",
          html: "系統發生問題，請稍後再重新操作一次<br />如果仍然顯示此錯誤，請向平台反映此問題<br /><br />造成您的不便，我們感到十分抱歉 !",
          //html: true,
          // showConfirmButton: false,
          backdrop: true,
          //position: "top-end",
          //timer: 6000,
          width: '550px'
        });
        _error_dialog_timeout_id = 0;
      }
      if (_error_dialog_timeout_id != 0) {
        clearTimeout(_error_dialog_timeout_id);
      }
      _error_dialog_timeout_id = setTimeout(showErrorSwal, 1500);
    }
  },
  watch: {
    // isloadingDone: function (value, oldValue) {
    //   console.log("GlobalMixin", "loadingDone", value, oldValue)
    // }
    // loadingDone: function (value, oldValue) {
    //   // console.log(
    //   //   "global-mixin.js",
    //   //   "isloadingDone",
    //   //   value,
    //   //   oldValue)
    // }
  }
}

module.exports = GlobalMixin