const $ = require('jquery')

const VueInlineCss = {
  install: function (Vue) {
    Vue.directive('style', {
      update: function (el, binding) {
        // $(function () {
        //   console.log('VueInlineCss.js',
        //     'InlineStyleDirective',
        //     'update',
        //     'el',
        //     el)
        //   console.log('VueInlineCss.js',
        //     'InlineStyleDirective',
        //     'update',
        //     'binding',
        //     binding)
        //   console.log('VueInlineCss.js',
        //     'InlineStyleDirective',
        //     'update',
        //     '$(head)',
        //     $("head"))
        // })

        var $el = $(el)
        var $style = $(`<style>${binding.value}</style>`)
        //$el.append($style)
        $("head").append($style)
      },
    })
  }
}

module.exports = VueInlineCss