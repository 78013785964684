<template>
  <div class="container my-container" v-body-classname-remove="'primary-body'">
    <vue-inet-content
      :api_name="'Member-Forget-Password'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

const api = require("@/api");

export default {
  data() {
    return {
      metadata: {
        email: "",
        verify_code: "",
        password: "",
        verify_password: "",

        identifier: "",

        verify_sended: false,
        show_verify_only: false,
        show_password_only: false,

        next: this.next,
      },
      metadata_ready: false,
    };
  },

  computed: {
    ...mapGetters(["RegisterEmail"]),
  },

  methods: {
    ...mapActions(["setRegisterEmail"]),

    afterResetPassword: function(response) {
      this.hideLoading("reset-password");

      if (response.status_code == 202) {
        this.$swal({
          icon: "success",
          text: "密碼已完成變更，請重新登入 !",
          showConfirmButton: false,
          position: "top-end",
          timer: 3000,
        }).then(() => {
          this.$router.push({ name: "MemberLogout" });
        });

        return;
      }

      let $result = response.result;

      //console.log("$result :", $result);

      let message = `${$result.data} (${$result.result_number})`;

      this.$swal({
        icon: "error",
        message: message,
      });

      if ($result.result_number == -1001401) {
        this.$swal({
          icon: "error",
          text: message,
          showConfirmButton: true,
        });

        this.email = "";
        this.verify_code = "";
        this.password = "";
        this.verify_password = "";

        this.identifier = "";

        this.verify_sended = false;
        this.show_verify_only = false;
        this.show_password_only = false;

        this.setRegisterEmail("");
      }
    },

    doResetPassword: function() {
      let payload = {
        identifier: this.currentMetadata.identifier,
        token: this.currentMetadata.verify_code,
        password: this.currentMetadata.verify_password,
      };

      this.showLoading("reset-password");

      api.ResetPassword(payload).then(this.afterResetPassword);
    },

    afterResponse: function(response) {
      this.hideLoading("send-reset-password");

      var result = response.result;

      if (response.status_code == 500) {
        if (_.isString(result)) {
          this.$swal({
            icon: "error",
            text: `系統發生意外錯誤 - ${result}`,
          });
        }

        return;
      }
      if (_.has(result, "data")) {
        let $data = result.data;

        if (response && response.status_code == 200) {
          this.metadata.identifier = $data.identifier;
          this.metadata.verify_sended = true;

          this.setRegisterEmail(this.currentMetadata.email);

          this.$swal({
            icon: "info",
            text:
              "已將驗證碼發送至您輸入的信箱，請依照信件中的指示進行操作，如果尚未收到信件，請稍後片刻，或重新整理您的信件網頁畫面",
            showConfirmButton: true,
            backdrop: false,
            position: "top-end",
            //timer: 8000,
          });

          this.metadata_ready = true;

          return;
        }

        let message = "";

        if (_.has($data, "message")) {
          message = `${$data.message} (${result.result_number})`;
        } else {
          message = `${$data} (${result.result_number})`;
        }

        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          if (result.result_number == -1001201) {
            this.$router.push({ name: "MemberCenter" });
            return;
          }
        });
      }
    },
    next: function() {
      // let _this = this;
      if (
        this.currentMetadata.identifier != "" &&
        this.currentMetadata.verify_sended == true &&
        this.currentMetadata.verify_code != ""
      ) {
        this.doResetPassword();
        return;
      }

      let payload = {
        "reset-type": "email",
        email: this.currentMetadata.email,
      };

      this.showLoading("send-reset-password");

      if (this.currentRouteName == "MemberReset") {
        api.SendResetPassword(payload).then(this.afterResponse);
      } else if (this.currentRouteName == "MemberForget") {
        api.SendForgotPassword(payload).then(this.afterResponse);
      }
    },
  },

  async mounted() {
    if (_.has(this.querys, "i") && _.has(this.querys, "code")) {
      // comes from url in mail
      let _this = this;

      this.metadata_ready = false;

      this.metadata.identifier = this.querys.i;
      this.metadata.verify_code = this.querys.code;

      this.metadata.verify_sended = true;
      this.metadata.show_password_only = true;

      //console.log("MemberRegisterVerify", "Querys : ", this.querys);
      // let payload = {
      //   identifier: this.querys.i,
      //   code: this.querys.code,
      // };

      // this.verifyEmail(payload);
      this.metadata_ready = true;

      this.$swal({
        icon: "info",
        text: "請輸入您要重新設定的新密碼，並依照畫面指示完成密碼重新設定",
        showConfirmButton: true,
        position: "top-end",
        //timer: 10000,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> 知道了',
      });

      return;
    }

    // not come from email, always from login step.
    if (
      this.RegisterEmail === undefined ||
      this.RegisterEmail == null ||
      this.RegisterEmail.length == 0
    ) {
      this.$swal({
        icon: "warning",
        text:
          "請輸入您註冊時使用的電子郵件信箱位址後，點選送出，並依照信件提示完成重設密碼流程",
        showConfirmButton: true,
        backdrop: false,
        position: "top-end",
        //timer: 8000,
      });

      this.metadata_ready = true;

      return;
    } else {
      this.metadata.email = this.RegisterEmail;

      this.next();
    }
  },
};
</script>
