<template>
  <div id="app">
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation">
      <!-- <img alt="Vue logo" src="../assets/logo.png" />
      <HelloWorld msg="Welcome to Your Vue.js App" /> -->
      <vue-inet-content
        ref="TopHeaderMenu"
        :api_name="'TopHeaderMenu'"
        :metadata="metadata"
        :metadata_ready="metadata_ready"
      />
      <div class="container">
        <iNetContent :api_name="'HeaderMenu'" v-if="false" />
        <vue-inet-content
          ref="HeaderMenu"
          :api_name="'HeaderMenu'"
          :metadata="metadata"
          :metadata_ready="metadata_ready"
        />
      </div>
    </nav>
    <!-- 主標題列 -->
    <div class="my-bgcolor-primary" v-if="isProductionTypeMenuVisible">
      <div class="container my-1th-container">
        <vue-inet-content
          ref="ChildPageMenu"
          :api_name="'ChildPageMenu'"
          :metadata="metadata"
          :metadata_ready="metadata_ready"
        />
      </div>
    </div>
    <div class="my-bgcolor-primary" v-if="currentRouteName == 'Member'">
      <div class="container my-1th-container">
        <vue-inet-content :api_name="'ChildPageMenu-Member'" />
      </div>
    </div>
    <!-- /主標題列 -->
    <router-view
      ref="router-view-main"
      v-bind="$attrs"
      v-on="$listeners"
      @loading="onLoading"
      @redirectTo="onRedirectTo"
      :loadingDone="isLoadingDone"
    />
    <!--/聯絡我們-->
    <vue-inet-content :api_name="'Footer'" />
    <!-- loading spinner -->
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="canCancel"
        :on-cancel="onCancel"
        :is-full-page="true"
      ></loading>
    </div>
    <!-- /Loading spinner -->
  </div>
</template>
<script>
// @ is an alias to /src
import "current-script-polyfill";
// import "@babel/polyfill";
import _ from "lodash";
import $ from "jquery";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import iNetContent from "@/components/iNetContent.vue";
import { AppHeightFixMixin } from "./mixins";
import { mapGetters } from "vuex";

const ua = require("ua-parser-js");

const excludeToHideMenu = ["Index", "Login", "Member"];
const config = require("@/config");

const delayToHideLoading = 1000;

var loadingTimeoutId = 0;

export default {
  name: "App",
  data() {
    return {
      isLoading: false,
      isLoadingDone: false,
      canCancel: false,
      metadata: {
        is_activable: false,
        channel: "",
        is_login: this.isLogin,
        routeName: this.currentRouteName,
        gotoChannel: this.gotoChannel,
        lastRedirectId: 0,
      },
      metadata_ready: true,
      loadingSequenceKeys: [],
    };
  },
  methods: {
    gotoChannel(routeName, channel) {
      if (channel == null || channel == undefined || channel == "") {
        channel = config["default-channel"];
      }

      var path = this.$router.resolve({
        name: routeName,
        params: channel,
      }).href;

      location.href = path;
    },
    checkExistsToUpdate(refName) {
      if (_.has(this.$refs, refName)) {
        let $el = this.$refs[refName];
        if ($el === undefined) return;
        if ($el == null) return;

        //$el.$forceUpdate();
        //if (_.isFunction($el, forceUpdate) == false) return;
        console.log("checkExistsToUpdate for : ", refName);
        $el.forceUpdate();
      }
    },
    updateTemplateComponents() {
      // debugger;

      this.checkExistsToUpdate("TopHeaderMenu");
      this.checkExistsToUpdate("HeaderMenu");
      this.checkExistsToUpdate("ChildPageMenu");
      this.checkExistsToUpdate("router-view-main");
    },
    onRedirectTo(param) {
      // debugger;
      console.log("onRedirectTo !!!");

      this.metadata.lastRedirectId = this.currentTimeStamp;

      setTimeout(this.updateTemplateComponents, 500);

      this.$router.push(param);
    },
    onLoading(status, key, foreceClose = false) {
      let _this = this;

      let keys = this.getObserveValue(this.loadingSequenceKeys);

      //console.log("on Loading...", status, key);
      let $index = keys.indexOf(key);

      if (status == true) {
        if ($index == -1) {
          //console.log("loading effect", "push");
          keys.push(key);
          this.isLoading = true;
        }
      } else {
        if ($index != -1) {
          //console.log("loading effect", "slice");
          keys = _.remove(keys, key);
        }
      }

      // console.log(
      //   "loading effect",
      //   "status :",
      //   status,
      //   "$index :",
      //   $index,
      //   "loadingSequenceKeys.length :",
      //   keys.length,
      //   "loadingSequenceKeys :",
      //   keys
      // );

      this.loadingSequenceKeys = keys;

      //this.isLoading = keys.length > 0;
      let visible = keys.length > 0;

      if (foreceClose == true && status == false) {
        visible = false;
      }

      if (visible == false) {
        if (loadingTimeoutId != 0) {
          clearTimeout(loadingTimeoutId);
          loadingTimeoutId = 0;
        }
        loadingTimeoutId = setTimeout(function() {
          _this.isLoading = false;
        }, delayToHideLoading);
      } else {
        this.isLoading = true;
      }
    },
    onCancel() {},
  },
  computed: {
    ...mapGetters(["IsLoading"]),

    isProductionTypeMenuVisible() {
      var index = -1;
      excludeToHideMenu.forEach((item) => {
        if (item == this.currentRouteName) {
          index = 1;
        }
      });

      // console.log(
      //   "App.vue",
      //   "excludeToHideMenu",
      //   excludeToHideMenu,
      //   "this.currentRouteName",
      //   this.currentRouteName,
      //   "index",
      //   index
      // );
      return index == -1;
    },
    routerName() {
      return this.currentRouteName.toLowerCase();
    },
    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
    currentTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  components: {
    Loading,
    iNetContent,
  },
  mixins: [AppHeightFixMixin],
  beforeMount() {
    if (this.$route.name == null) {
      this.$router.push("/");
    }
    // if (this.error.statusCode == 404) {
    //   this.$router.push("/");
    // }
  },
  mounted() {
    // console.log("App.vue", "this.currentRouteName :", this.currentRouteName);
    if (
      this.currentRouteName == "Film" ||
      this.currentRouteName == "FreeFilm"
    ) {
      this.metadata.is_activable = true;
      this.metadata.channel = this.channel;
    }

    this.$on("loading", this.Loading);
    // console.log("App.vue", "isLogin :", this.isLogin);
    // console.log("App.vue", "currentUserId :", this.currentUserId);
    // console.log("App.vue", "currentUserName :", this.currentUserName);

    var userAgent = navigator.userAgent;
    var { browser, device, os } = ua(userAgent);
    // $("body")
    //   .removeClass("mobile")
    //   .addClass("mobile");
    //alert(browser.name);
  },
  watch: {
    isLoading: function(value, prevValue) {
      //this.isLoading = value;
      // console.log(
      //   "App.vue",
      //   "isLoading",
      //   value,
      //   prevValue,
      //   this.getObserveValue(this.loadingSequenceKeys)
      // );
      if (prevValue == true && value == false) {
        // console.log(
        //   "App.vue",
        //   "isLoading",
        //   this.getObserveValue(this.loadingSequenceKeys)
        // );
        this.isLoadingDone = true;
        return;
      }

      this.isLoadingDone = false;
    },
  },
};
</script>
