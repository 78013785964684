const GlobalStorage = {
  install: function (Vue) {
    window.$sessionStorage = Vue.$sessionStorage
    // Vue.mixin({
    //   computed: {
    //     "$localStorage": function () {
    //       return Vue.$localStorage
    //     }
    //   }
    // })
  }
}

module.exports = GlobalStorage