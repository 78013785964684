<template>
  <slide-up-down :class="this.setClass" :active="showOption" :duration="500">
    <div class="col-12" v-html="content"></div>
  </slide-up-down>
</template>
<script>
import SlideUpDown from "vue-slide-up-down";

export default {
  data() {
    return {
      showOption: false,
      // content: "",
      // "set-class": "",
    };
  },
  props: ["content", "set-class"],
  methods: {
    toggleShow() {
      this.showOption = !this.showOption;
      //console.log("this.showOption", this.showOption);
    },
  },
  computed: {
    status() {
      return this.showOption;
    },
    // contentFromParent() {
    //   return this.content;
    // },
    // setClassFromParent() {
    //   return this.setClass;
    // },
  },
  components: { SlideUpDown },
  // mounted() {
  //   console.log(this);
  // },
  // watch: {
  //   contentFromParent: function(value) {
  //     console.log("watch content :", value);
  //   },
  // },
};
</script>
