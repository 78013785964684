<template>
  <div v-body-classname-remove="'primary-body'">
    <!-- /film?mock_key=0378eaec-1049-404d-bd9c-5aa5cbc64436&mock_man_no=0531 -->
    <!-- /film?mock_key=0378eaec-1049-404d-bd9c-5aa5cbc64436&mock_man_no=oxox -->
    <!-- 內容 -->
    <vue-inet-content
      :api_name="apiNameByChannel"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
    <!-- /內容 -->
  </div>
</template>
<script>
const _ = require("lodash");
const api = require("@/api");
//const { RouteMixin } = require("../mixins");
const config = require("@/config");

export default {
  name: "Product",
  data() {
    return {
      metadata: {
        channel: "",
      },
      metadata_ready: false,
    };
  },
  computed: {
    apiNameByChannel() {
      switch (this.channel) {
        case "letstalk":
          return "Product-LetsTalk";
        case "studioclassroom":
          return "Product-StudioClassroom";
      }
    },
    courses() {},
    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
  },
  methods: {
    getCourses() {
      if (_.has(this.routeParams, "channel") == false) {
        return "[]";
      }

      var _this = this;
      //_this.$emit("loading", true, "loading-product");
      this.showLoading("product");

      api.GetCoursesInThisMonth(this.routeParams["channel"]).then((courses) => {
        //console.log("computed()", "courses", courses);
        //console.log(courses);
        _this.metadata.courses = courses;
        _this.metadata.channel = _this.channel;

        //_this.$emit("loading", false, "loading-product");
        _this.hideLoading("product");

        _this.metadata_ready = true;
        //console.log("Product.vue", "GetCoursesInThisMonth", courses);
      });
    },
  },
  //mixins: [RouteMixin],
  mounted() {
    //console.log(this.routeParams);
    this.getCourses();
    this.metadata.channel = this.channel;

    // console.log(
    //   "/film?mock_key=0378eaec-1049-404d-bd9c-5aa5cbc64436&mock_man_no=0531"
    // );
    // console.log(
    //   "/film?mock_key=0378eaec-1049-404d-bd9c-5aa5cbc64436&mock_man_no=oxox"
    // );
  },
};
</script>
