import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: require('./state'),
  mutations: require('./mutations'),
  actions: require('./actions'),
  getters: require('./getters')
})
