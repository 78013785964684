<template>
  <div class="container my-container">
    <vue-inet-content
      :api_name="'Shop-Confirm'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
    <sweet-modal
      ref="payment-modal"
      modal-theme="dark"
      overlay-theme="dark"
      :blocking="blocking_modal"
      width="80%"
      height="80%"
      v-on:close="paymentModalClosed"
    >
      <iframe :src="payment_url" frameborder="0" allowfullscreen></iframe>
    </sweet-modal>
  </div>
</template>
<script>
const _ = require("lodash");
const api = require("@/api");
const config = require("../config");

export default {
  data() {
    return {
      metadata: {
        me: {},
        member_checking_status: false,
        update: this.update,
      },
      metadata_ready: false,
      stopCheckingCart: false,
      payment_url: "",
      blocking_modal: true,
      watch_modal_url: false,
      sce_payment_order_id: "",
    };
  },
  computed: {
    // ...mapGetters(["ShoppingCart"]),
    // ShoppingCart() {
    //   return this.$sessionStorage.get("shopping-cart", []);
    // },
    PaymentModal() {
      if (this.$refs["payment-modal"]) {
        return this.$refs["payment-modal"];
      }

      return null;
    },
    PaymentModalUrl() {
      if (this.watch_modal_url == false) return null;

      let $el = this.PaymentModal.$el;

      var iframes = $el.getElementsByTagName("iframe");
      if (iframes.length > 0) {
        var $iframe = iframes[0];

        if ($iframe.attributes["src"]) {
        }

        //debugger;

        //console.log($iframe, $iframe.attributes["src"]);

        return $iframe.attributes["src"];
      }
    },
  },
  methods: {
    paymentModalClosed() {
      this.watch_modal_url = false;
      //console.log('paymentModalClosed !');
      //this.refreshOrders();
      this.$router.push({ name: "MemberCenter" });

      // this.$swal({
      //   icon: "success",
      //   text: "請點選訂單編號進行結帳程序",
      //   showConfirmButton: true,
      //   position: "top-end",
      // });
    },
    closePaymentModal() {
      if (this.PaymentModal) {
        this.PaymentModal.close();
      }
    },
    showPaymentModal() {
      if (this.PaymentModal) {
        let _this = this;

        //this.watch_modal_url = true;
        //this.watchModalUrl();

        setTimeout(function() {
          //_this.payment_url = e.srcElement.href;
          //this.payment_url = url;
          _this.PaymentModal.open();
          _this.watch_modal_url = true;
        }, 250);
        setTimeout(this.watchModalUrl, 3000);
      }

      return false;
    },
    update() {
      let $this = this;
      let message = "確定輸入資料正確 ?";

      this.$swal
        .fire({
          // title: 'Are you sure?',
          html: message,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#888",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.showLoading("update-me");

            this.metadata.member_checking_status = true;
            this.prevent_multiple_updateing = true;

            var response = await api.UpdateMe($this.metadata.me);

            this.hideLoading("update-me");

            if (
              response &&
              _.has(response, "status_code") &&
              response["status_code"] == 200
            ) {
              var cart = this.currentMetadata.ShoppingCart;
              if (cart.length == 0) {
                return false;
              }

              var $item = cart[0];

              var payload = {
                item_id: $item.item_id,
              };

              this.showLoading("create-order");

              var result = await api.CreateOrder(payload);

              this.hideLoading("create-order");

              if (result.status_code == 200) {
                var $data = result.result.data;
                var sce_payment_order_id = $data.sce_payment_order_id;

                this.sce_payment_order_id = sce_payment_order_id;

                let url = `https://scepayment.sce.pccu.edu.tw/payweb/info/?orderid=${sce_payment_order_id}`;
                this.payment_url = url;
                this.showPaymentModal();
                //window.open(url, "_blank");

                this.stopCheckingCart = true;

                this.clearShoppingCart();

                return false;
              }
            }

            this.metadata.member_checking_status = false;
            this.prevent_multiple_updateing = false;
          }
        });

      return false;
    },
    getMe: async function() {
      var response = {};

      try {
        this.metadata.member_checking_status = true;
        response = await api.GetMe();
      } catch (ex) {
        if (ex == "Network Error") {
          this.showBackendError();
        }
      }

      if (response.status_code == 200) {
        this.metadata.member_checking_status = false;
        // debugger;
        //console.log("getMe response : ", response);
        // var $result = response.result;

        // return { me: $result };
        return response;
      }

      return { status_code: response.status_code, response };
    },
    checkIsCartEmpty: function() {
      let _this = this;
      const checkIsEmpty = function() {
        if (_this.$sessionStorage.get("shopping-cart", []).length == 0) {
          //this.$router.push({ name: "Shop" });
          return true;
        }

        return false;
      };

      const check = function() {
        if (_this.stopCheckingCart) return;

        if (checkIsEmpty() == true) {
          if (_this.stopCheckingCart) return;

          _this.$router.push({ name: "Shop" });
          return;
        }

        //setTimeout(check, 200);
      };

      check();
    },
  },
  mounted() {
    let _this = this;

    this.showLoading("get-me");
    this.getMe().then((response) => {
      // console.log(me);
      // debugger;

      if (
        _.has(response, "status_code") &&
        response["status_code"] !== undefined
      ) {
        var $result = response.result;
        if (
          _.has($result, "result_number") &&
          $result["result_number"] == -1001103
        ) {
          _this.$swal({
            icon: "error",
            text:
              "您的帳號尚未完成驗證，請先進行登入，依照畫面指示操作，完成驗證程序",
            showConfirmButton: true,
            backdrop: false,
            position: "top-end",
            //timer: 10000,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> 知道了',
          });
          this.$emit("redirectTo", { name: "MemberLogout" });
          return;
        }
        _this.metadata.me = $result;
      }

      this.checkIsCartEmpty();
      this.metadata.ShoppingCart = this.$sessionStorage.get(
        "shopping-cart",
        []
      );

      _this.metadata_ready = true;
      // if (_.has(me, "me")) {
      //   me = me["me"];
      // }
      // _this.metadata.me = me;
      // _this.email_before = me.email;
      //this.$emit("loading", false, "get-me");
      _this.hideLoading("get-me");

      // _this.metadata_ready = _this.$isLoadingDone;
    });
  },
};
</script>
