<template>
  <div name="member-center" v-body-classname-remove="'primary-body'">
    <vue-inet-content
      ref="member-center"
      :api_name="'Member-Center'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
    <sweet-modal
      ref="payment-modal"
      modal-theme="dark"
      overlay-theme="dark"
      :blocking="blocking_modal"
      width="80%"
      height="80%"
      v-on:close="paymentModalClosed"
    >
      <iframe
        width="560"
        height="315"
        :src="payment_url"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </sweet-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

const $ = require("jquery");
const api = require("@/api");

const { LoadingMixin } = require("@/mixins");

export default {
  name: "MemberCenter",
  data() {
    return {
      metadata: {
        me: {},
        orders: [],
        $this: this.$this,
        refresh: this.refreshOrders,
        refresh_status: false,
        member_checking_status: false,
        showPaymentModal: this.showPaymentModal,
        showChangePassword: this.showChangePassword,
        update: this.updateUserData,
        clickUpdate: this.clickUpdate,
      },
      metadata_ready: false,
      prevent_multiple_updateing: false,
      payment_url: "",
      blocking_modal: true,
      watch_modal_url: false,
      email_before: "",
    };
  },
  //mixins: [LoadingMixin],
  computed: {
    $this() {
      return this;
    },
    userId() {
      return this.currentUserId;
    },
    userName() {
      return this.currentUserName;
    },
    PaymentModal() {
      if (this.$refs["payment-modal"]) {
        return this.$refs["payment-modal"];
      }

      return null;
    },
    PaymentModalUrl() {
      if (this.watch_modal_url == false) return null;

      let $el = this.PaymentModal.$el;

      var iframes = $el.getElementsByTagName("iframe");
      if (iframes.length > 0) {
        var $iframe = iframes[0];

        if ($iframe.attributes["src"]) {
        }

        let $body = $($iframe)
          .contents()
          .find("body");

        //debugger;

        //console.log($iframe, $iframe.attributes["src"]);

        return $iframe.attributes["src"];
      }
    },
    // isLoadingDone() {
    //   return this.$attrs;
    // },
  },
  methods: {
    ...mapActions(["setRegisterEmail"]),

    confirmToResetPassword(isConfirm) {
      if (isConfirm) {
        this.setRegisterEmail(this.currentMetadata.me.email);
        this.$router.push({ name: "MemberReset" });
      }
    },

    showChangePassword($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.showConfirm("確定要修改您的密碼?").then(this.confirmToResetPassword);
    },
    watchModalUrl() {
      //debugger;
      if (this.watch_modal_url == false) return;

      let src = this.PaymentModalUrl;

      //console.log("PaymentModalUrl : ", src);

      //setTimeout(this.watchModalUrl, 100);
    },
    showPaymentModal(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.PaymentModal && e.srcElement) {
        let _this = this;

        //this.watch_modal_url = true;
        //this.watchModalUrl();

        setTimeout(function() {
          _this.payment_url = e.srcElement.href;
          //this.payment_url = url;
          _this.PaymentModal.open();
          _this.watch_modal_url = true;
        }, 250);

        setTimeout(this.watchModalUrl, 3000);
      }

      return false;
    },
    paymentModalClosed() {
      this.watch_modal_url = false;
      //console.log('paymentModalClosed !');
      this.refreshOrders();
    },
    closePaymentModal() {
      if (this.PaymentModal) {
        this.PaymentModal.close();
      }
    },
    refreshOrders: function(callback) {
      let _this = this;

      this.metadata.refresh_status = true;

      this.showLoading("refresh-order");

      this.checkOrders().then((orders) => {
        _this.hideLoading("refresh-order");
        _this.metadata.refresh_status = false;
        _this.metadata.orders = orders;

        if (callback) {
          callback(orders);
        }
      });
    },
    checkOrders: async function() {
      this.showLoading("check-orders");
      var result = await api.CheckOrders();
      this.hideLoading("check-orders");

      if (result.status_code == 200) {
        //console.log("result : ", result);
        var $result = result.result;

        return $result.data;
      }

      return [];
    },
    getOrders: async function() {
      var response = await api.GetOrders();

      if (response.status_code == 200) {
        //console.log("getOrders response : ", response);
        var $result = response.result;

        return $result.data;
      }

      return [];
    },
    getMe: async function() {
      var response = {};

      try {
        response = await api.GetMe();
      } catch (ex) {
        if (ex == "Network Error") {
          this.showBackendError();
        }
      }

      if (response.status_code == 200) {
        //console.log("getMe response : ", response);
        var $result = response.result;

        return { me: $result };
      }

      return { status_code: response.status_code, response };
    },

    updateUserData: async function() {
      if (this.prevent_multiple_updateing == true) return;

      this.prevent_multiple_updateing = true;

      this.metadata.member_checking_status = true;

      let _this = this;

      // let payload = {
      //   email: this.metadata.me.email,
      // };

      //this.showLoading("email-change-check");
      let emailChanged = false;
      //let response = await api.CheckEmailChanged(payload);

      //this.hideLoading("email-change-check");

      // if (response.status_code == 200) {
      //   let $result = response.result;
      //   if (_.has($result, "check")) {
      //     emailChanged = $result.check;
      //   }
      // }
      // console.log("MemberCenter.vue", "this.metadata.me", this.metadata.me);
      // debugger;
      if (this.email_before != this.metadata.me.email) {
        emailChanged = true;
      }

      let payload = this.metadata.me;

      let message = "確定要更新您的個人資料 ?";

      if (emailChanged) {
        message +=
          "<br />同時因為您的 E-mail 有做變更，因此更新完成後，系統將會提示您重新完成電子郵件驗證";
      }

      this.$swal
        .fire({
          // title: 'Are you sure?',
          html: message,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#888",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
        })
        .then(async (result) => {
          if (result.isConfirmed == false) {
            this.metadata.member_checking_status = false;
            this.prevent_multiple_updateing = false;

            return false;
          }

          if (result.isConfirmed) {
            // Swal.fire(
            //   'Deleted!',
            //   'Your file has been deleted.',
            //   'success'
            // )
            //console.log("confirm yes");

            this.showLoading("update-me");

            var response = await api.UpdateMe(payload);

            this.metadata.member_checking_status = false;
            this.prevent_multiple_updateing = false;

            this.hideLoading("update-me");

            if (response.status_code > 300) {
              var $result = response.result;
              if ($result.result_number == -1001103) {
                this.$emit({ name: "MemberLogout" });
                return;
              }
            }

            if (response.status_code == 200) {
              // console.log("updateMe response : ", response);
              let $result = response.result;

              // debugger;

              _this.metadata.me = $result.man;

              let message = "資料更新完成";

              if ($result.redirect_to_login == true) {
                message =
                  "資料更新完成，系統將登出您的帳號，請重新登入並依照畫面提示完成信箱驗證";
              }

              _this
                .$swal({
                  icon: "info",
                  text: message,
                  showConfirmButton: false,
                  backdrop: false,
                  position: "top-end",
                  timer: 3000,
                })
                .then(() => {
                  if ($result.redirect_to_login == true) {
                    var path = _this.$router.resolve({ name: "MemberLogout" })
                      .href;
                    // _this.$router.push({ name: "Film" });
                    location.href = path;
                  }
                });
            }
          }
        });
    },
  },
  mounted() {
    let _this = this;

    if (this.isLogin == false) {
      this.$router.push({ name: "MemberLogin" });
      return;
    }

    // this.$emit("loading", true, "get-orders");
    // this.$emit("loading", true, "get-me");
    this.showLoading("get-orders");
    this.showLoading("get-me");

    //console.log("cart :", this.currentShoppingCart);
    this.getOrders().then((orders) => {
      _this.metadata.orders = orders;
      //this.$emit("loading", false, "get-orders");
      this.hideLoading("get-orders");

      _this.metadata_ready = this.$isLoadingDone;
    });

    this.getMe().then((me) => {
      // console.log(me);
      if (_.has(me, "status_code") && me["status_code"] !== undefined) {
        var $result = me.response.result;
        if ($result.result_number == -1001103) {
          this.$swal({
            icon: "error",
            text:
              "您的帳號尚未完成驗證，請先進行登入，依照畫面指示操作，完成驗證程序",
            showConfirmButton: true,
            backdrop: false,
            position: "top-end",
            //timer: 10000,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> 知道了',
          });
          this.$emit("redirectTo", { name: "MemberLogout" });
          return;
        }
      }
      if (_.has(me, "me")) {
        me = me["me"];
      }
      _this.metadata.me = me;
      _this.email_before = me.email;
      //this.$emit("loading", false, "get-me");
      this.hideLoading("get-me");

      _this.metadata_ready = this.$isLoadingDone;
    });
  },
  watch: {
    // PaymentModalUrl (value) {
    //   if (this.watch_modal_url == false) return;
    //   console.log('url : ', value);
    //   return value;
    // },
    isLoadingDone: function(value, oldValue) {
      //console.log("MemberCenter.vue", "isloadingDone", value, oldValue);
    },
  },
};
</script>
