const Vue = require('vue')
const _ = require('lodash')
const axios = require('axios')
const { parse } = require('uuid')
const jwt = require('jsonwebtoken')

const config = require('./config')

// console.log("api.js", "config :", config)

const inet_api_host = config['inet-api-host']

const getToken = () => {
  var vue = Vue.default
  // console.log(Vue);

  var value = vue.$sessionStorage.get('mock-jwt-token', null)
  var from = "mock-jwt-token"

  if (value == null) {
    value = vue.$sessionStorage.get('jwt-token', null)
    from = "jwt-token"
  }

  // console.log(vue.$sessionStorage.keys())
  // debugger

  // value = vue.$sessionStorage.get('jwt-token', null)

  if (value == null) {
    value = vue.$localStorage.get('jwt-token', null)
    from = "localstorage.jwt-token"
  }

  var otToken = vue.$sessionStorage.get('jwt-ot-token', null)

  // if (value !=) {
  //   var result = { token: value.token, from }
  //   if (otToken && _.isObject(otToken)) {
  //     result["ot-token"] = otToken
  //   }

  //   return result
  // }

  if (otToken != null && _.isObject(otToken)) {
    return {
      token: null,
      "ot-token": otToken,
      from: null
    }
  }
  else if (value != null && _.isObject(value)) {
    return {
      token: value.token,
      from: from
    }
  }

  return { token: null, from: null }
}

const getConfig = (host, url, params, data, method) => {
  var config = {
    method,
    baseURL: host,
    timeout: 30000, // axios timeout setting to 10s
    url,
    params,
    data,
    responseType: 'json',
    responseEncoding: 'utf8',
  }

  var tokenResult = getToken()
  var token = tokenResult.token || tokenResult["ot-token"]
  var from = tokenResult.from

  if (token) {
    // console.log(">> token from : ", from)
    // console.log(token)
    // console.log(config)
    config['headers'] = { Authorization: `Bearer ${token}` }
  }

  return { axios, config }
}

const _generic = (host, url, params, data, method) => {
  return new Promise((resolve, reject) => {
    var { axios, config } = getConfig(host, url, params, data, method)

    // console.log("api.js", "_generic", "config", config)

    axios(config).then((response) => {
      resolve(response.data)
    }).catch((error) => {
      var response = error['response']
      if (response === undefined) {
        response = error.message
      }

      if (_.isObject(response)) {
        reject(response.data)
      }
      else {
        reject(response)
      }
    })
  })
}

const _get = (host, url, params = null) => {
  return _generic(host, url, params, 'get')
}
const _post = (host, url, data = null) => {
  return _generic(host, url, null, data, 'post')
}
const _put = (host, url, data) => {
  return _generic(host, url, null, data, 'put')
}

const getList = async (url) => {
  try {
    let uri = `${inet_api_host}${url}`
    const { data } = await axios.get(uri)

    return data
  }
  catch (ex) {
    throw ex
  }
}

const getBoardContent = async (url) => {

  try {
    let uri = `${inet_api_host}${url}`
    const { data } = await axios.get(uri)

    return data
  }
  catch (ex) {
    throw ex
  }
}

const getProducts = async () => {
  try {
    let url = config["inet-api"]["Products"]

    const result =
      await _get(
        config['inet-api-host'],
        url
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const parseApiResultFromDigichannel = (data, defaultValue = {}) => {
  var result = defaultValue

  if (data.status_code >= 200 && data.status_code < 300) {
    result = data.result
  }
  else {
    result = defaultValue
  }

  return result
}

const getCoursesInThisMonth = async (productType) => {
  try {
    let uri = `${config["digichannel-api-host"]}/course/current-month?product-type=${productType}`

    const { data } = await axios.get(uri)

    return parseApiResultFromDigichannel(data)
  }
  catch (ex) {
    throw ex
  }
}

const getUserIdNumberExists = async (id) => {
  try {
    //let uri = `${config["digichannel-api-host"]}/man/check/id?userid=${userId}`
    let uri = `${config["digichannel-api-host"]}/man/check/id`
    let postdata = {
      "id": id
    }

    const { data } = await axios.post(uri, postdata)

    return parseApiResultFromDigichannel(data)
  }
  catch (ex) {
    throw ex
  }
}

const getUserAccountExists = async (account) => {
  try {
    //let uri = `${config["digichannel-api-host"]}/man/check/id?userid=${userId}`
    // let uri = `${config["digichannel-api-host"]}/man/check/account`
    let url = '/man/check/account'
    let postdata = {
      "account": account
    }

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return response

    // const { data } = await axios.post(uri, postdata)

    // return parseApiResultFromDigichannel(data)
  }
  catch (ex) {
    throw ex
  }
}

const getUserEmailExists = async (email) => {
  try {
    //let uri = `${config["digichannel-api-host"]}/man/check/id?userid=${userId}`
    let url = `/man/check/email`
    let postdata = {
      "email": email
    }

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const createUser = async (form) => {
  try {
    let uri = `${config["digichannel-api-host"]}/man/register`

    const { data } = await axios.post(uri, form)

    return data
  }
  catch (ex) {
    throw ex
  }
}

const login = async (postdata) => {
  try {
    const result =
      await _post(
        config["digichannel-api-host"],
        "/man/login",
        postdata)

    return result
  }
  catch (ex) {
    throw ex
  }
}

const sendVerify = async (postdata) => {
  try {
    const result =
      await _post(
        config["digichannel-api-host"],
        "/man/email/send-verify",
        postdata)

    return result
  }
  catch (ex) {
    throw ex
  }
}

const verifyEmail = async (postdata) => {
  try {
    const result =
      await _post(
        config["digichannel-api-host"],
        "/man/email/verify",
        postdata)

    return result
  }
  catch (ex) {
    throw ex
  }
}

const sendForgotPassword = async (postdata) => {
  try {
    const result =
      await _post(
        config["digichannel-api-host"],
        "/man/password/send-forgot",
        postdata)

    return result
  }
  catch (ex) {
    throw ex
  }
}

const sendResetPassword = async (postdata) => {
  try {
    const result =
      await _post(
        config["digichannel-api-host"],
        "/man/password/send-reset",
        postdata)

    return result
  }
  catch (ex) {
    throw ex
  }
}

const resetPassword = async (postdata) => {
  try {
    const result =
      await _post(
        config["digichannel-api-host"],
        "/man/password/reset",
        postdata)

    return result
  }
  catch (ex) {
    throw ex
  }
}

const getShopItems = async (postdata) => {
  try {
    let url = '/shop/items'

    const result =
      await _post(
        config["digichannel-api-host"],
        url,
        postdata
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const createOrder = async (postdata) => {
  try {
    let url = '/order/new'

    const result =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const getOrders = async (postdata) => {
  try {
    let url = '/order/list'

    const result =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const checkOrders = async (postdata) => {
  try {
    let url = '/order/check'

    const result =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const getPaidOrders = async () => {
  try {
    let url = '/order/paid'

    const result =
      await _post(
        config['digichannel-api-host'],
        url
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const getFreeMedia = async (channel, date) => {
  try {
    let url = `/media/free/${channel}/${date}`

    const result =
      await _get(
        config['digichannel-api-host'],
        url
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const getCourseList = async (postdata, channel = "") => {
  try {
    let url = '/course/list'
    if (channel != "") {
      url += `/${channel}`
    }

    const result =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return result
  }
  catch (ex) {
    throw ex
  }
}

const getMonthCourseList = async (channel, year, month, postdata) => {
  try {
    let url = `/course/list/${channel}/${year}-${month}-1`

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const getMedia = async (channel, year, month, day, postdata) => {
  try {
    let url = `/media/member/${channel}/${year}-${month}-${day}`

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const sendContactUs = async (postdata) => {
  try {
    let url = `/site/send/contact-us`

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        postdata
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const getMe = async () => {
  try {
    let url = '/man/get'

    const response =
      await _get(
        config['digichannel-api-host'],
        url
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const updateMe = async (payload) => {
  try {
    let url = '/man/update'

    const response =
      await _put(
        config['digichannel-api-host'],
        url,
        payload,
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const verifyOTToken = async (payload) => {
  try {
    let url = '/man/update-ott'

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        payload
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

const checkEmailChanged = async (payload) => {
  try {
    let url = '/man/check/email-changed'

    const response =
      await _post(
        config['digichannel-api-host'],
        url,
        payload
      )

    return response
  }
  catch (ex) {
    throw ex
  }
}

module.exports = {
  "GetList": getList,
  "GetBoardContent": getBoardContent,
  "GetProductsBlock": getProducts,
  "GetCoursesInThisMonth": getCoursesInThisMonth,
  "GetUserAccountExists": getUserAccountExists,
  "GetUserIdNumberExists": getUserIdNumberExists,
  "GetUserEmailExists": getUserEmailExists,
  "CreateUser": createUser,
  "Login": login,
  "GetShopItems": getShopItems,
  "SendVerify": sendVerify,
  "VerifyEmail": verifyEmail,
  "SendForgotPassword": sendForgotPassword,
  "SendResetPassword": sendResetPassword,
  "ResetPassword": resetPassword,
  "CreateOrder": createOrder,
  "GetOrders": getOrders,
  "CheckOrders": checkOrders,
  "GetFreeMedia": getFreeMedia,
  "GetCourseList": getCourseList,
  "GetMonthCourseList": getMonthCourseList,
  "GetMedia": getMedia,
  "SendContactUs": sendContactUs,
  "GetMe": getMe,
  "UpdateMe": updateMe,
  "CheckEmailChanged": checkEmailChanged,
  "GetPaidOrders": getPaidOrders,
  "VerifyOTToken": verifyOTToken,
}