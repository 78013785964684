<template>
  <vue-inet-content
    :api_name="'Film-Video-List'"
    :metadata="metadata"
    :metadata_ready="metadata_ready"
  />
</template>
<script>
const _ = require("lodash");
const moment = require("moment");

const api = require("@/api");
const config = require("@/config");

const { LoadingMixin } = require("@/mixins");

export default {
  data() {
    return {
      metadata: {
        channel: this.channel,
        courses: [],
        year: this.year,
        month: this.month,
        getLink: this.getLink,
      },
      metadata_ready: false,
    };
  },
  //mixins: [LoadingMixin],
  computed: {
    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
    year() {
      if (_.has(this.routeParams, "year")) {
        return parseInt(this.routeParams["year"]);
      }
      return parseInt(moment().format("YYYY"));
    },
    month() {
      if (_.has(this.routeParams, "month")) {
        return parseInt(this.routeParams["month"]);
      }
      return parseInt(moment().format("M"));
    },
  },
  methods: {
    getLink(channel, year, month, day, is_available) {
      if (is_available == false) return "javascript:void(0);";

      return `/film4/${channel}/${year}/${month}/${day}`;
    },
  },
  mounted() {
    let _this = this;

    if (this.isLogin == false) {
      this.$router.push({ name: "MemberLogin" });
      return;
    }

    let payload = {};

    this.showLoading("get-month-course-list");

    api
      .GetMonthCourseList(this.channel, this.year, this.month, payload)
      .then((response) => {
        if (response.status_code == 200) {
          var courses = response.result;

          courses.forEach((course) => {
            var date = moment(course.display_date);
            var year = parseInt(date.format("YYYY"));
            var month = parseInt(date.format("M"));
            var day = parseInt(date.format("D"));

            course.year = year;
            course.month = month;
            course.day = day;
          });
          _this.metadata.year = this.year;
          _this.metadata.month = this.month;
          _this.metadata.courses = courses;
          _this.metadata.channel = this.channel;

          _this.metadata_ready = true;
        }

        this.hideLoading("get-month-course-list");
      });
  },
};
</script>
