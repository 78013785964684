const _ = require('lodash');
const $ = require('jquery');
// plugin : VeeValidate 3.x
const { ValidationProvider, ValidationObserver, extend } = require('vee-validate');
// rules list : https://logaretm.github.io/vee-validate/guide/rules.html#rules
const { required, email, between, confirmed, length } = require('vee-validate/dist/rules');
// messages in locale : zh-TW
//const { messages } = require('vee-validate/dist/locale/zh-TW.json');

const api = require('../api');

var userIdCheckDelayId = 0;
var accountCheckDelayId = -1;
var emailCheckDelayId = -1;

const updateCheckStatus = (className, status, message = null) => {
  var $el = $(className);
  if ($el.length > 0) {
    $el.attr('class', '')
      .text('');

    $el.addClass(className.substr(1))
      .addClass('check-status');

    if (status == 'prepare-to-check') {
      $el.addClass('prepare-check');
    }
    else if (status == 'check-passed') {
      $el.addClass('check-pass');
    }
    else if (status == 'check-failed') {
      $el.addClass('check-failed');
    }

    if (message != null) {
      $el.text(message);
    }
  }
}

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

const userIdCheckAsync = {

  params: ['className', 'min'],

  computesRequired: true,

  validate(value, { className, min = 4 }) {
    // console.log('VeeValidate.js',
    //   'userid-exists',
    //   'value :',
    //   value,
    //   'className :',
    //   className
    // )
    if (value.length < min) return false;

    var promise = new Promise(async (resolve) => {

      console.log('run id check...');
      updateCheckStatus(className, 'prepare-to-check', '帳號可用狀態確認中...');

      var response = await api.GetUserAccountExists(value)

      if (response && _.has(response, 'result')) {
        let $result = response.result;

        if ($result.check) {
          updateCheckStatus(className, 'check-failed');
          //return `帳號 ${value} 已經有人使用，請更換其他的帳號`;
        }
        else {
          updateCheckStatus(className, 'check-passed', `帳號 ${value} 可以使用`);
        }

        resolve($result.check)
        return
      }

      resolve(false)

      // setTimeout(function () {
      //   updateCheckStatus(className, 'prepare-to-check', '帳號可用狀態確認中...');
      // }, 1000);

      // if (userIdCheckDelayId != 0) {
      //   console.log('Clear userIdCheckDelayId :', userIdCheckDelayId);
      //   clearTimeout(userIdCheckDelayId);
      //   userIdCheckDelayId = 0;
      // }

      // userIdCheckDelayId = setTimeout(async function () {
      //   // ...
      // }, 3000)

      // console.log('userIdCheckDelayId :', userIdCheckDelayId);
    })

    return promise
      .then(check => {
        if (check) {
          // return {
          //   valid: false,
          //   data: { message: `會員帳號 ${value} 已經有人使用，請更換其他的會員帳號` }
          // }
          return `會員帳號 ${value} 已經有人使用，請更換其他的帳號`
        }
        else {
          return true
        }
      })
  }
}

const emailCheckAsync = {
  promise: null,

  params: ['className'],

  computesRequired: true,

  validate(value, { className }) {
    // let _this = this

    // console.log('### VeeValidate.js',
    //   'user-email-exists',
    //   'value :',
    //   value,
    //   'className : ',
    //   className
    // )

    var promise = new Promise(async (resolve) => {

      console.log('run email check...');
      // debugger
      updateCheckStatus(className, 'prepare-to-check', 'Email 可用狀態確認中...');

      var response = await api.GetUserEmailExists(value)

      if (response && _.has(response, 'result')) {
        let $result = response.result;

        if ($result.check) {
          updateCheckStatus(className, 'check-failed');
          // return `${value} 已經被註冊使用，無法重複使用`;
        }
        else {
          updateCheckStatus(className, 'check-passed', `可以使用 ${value} 進行註冊`);
        }

        resolve($result.check)
        return
      }

      resolve(false)

      // setTimeout(function () {
      //   updateCheckStatus(className, 'prepare-to-check', 'Email 可用狀態確認中...');
      // }, 1000);

      // if (emailCheckDelayId != 0) {
      //   clearTimeout(emailCheckDelayId)
      //   emailCheckDelayId = 0
      // }

      // emailCheckDelayId = setTimeout(async function () {
      //   // ...
      // }, 3000)
    })

    return promise
      .then(check => {
        if (check) {
          return `${value} 已經被註冊使用，無法重複使用`
        }
        else {
          return true
        }
      })
  }
}

const VeeValidate = {
  install: function (Vue) {

    Vue.component('v-validation', ValidationProvider);
    Vue.component('v-form', ValidationObserver);

    extend('required', {
      ...required,
      message: '必填欄位'
    });

    extend('length', {
      ...length,
      message: '長度必須為 {length} 個字'
    })

    extend('must-confirm-and-check', {
      ...required,
      validate: (value) => {
        // console.log('must-confirm-and-check',
        //   'value :', value)
        return value
      },
      message: '請在確認後勾選此選項表示同意'
    });

    extend('email', {
      ...email,
      message: '此欄位必須為 Email'
    });

    extend('between', {
      ...between,
      message: (fieldName, placeholders) => `此欄位限制最少為 ${placeholders.min} 個字，最多為 ${placeholders.max} 個字`
    });

    // custom rules
    extend('minmax', {
      validate(value, args) {
        const length = value.length;

        return length >= args.min && length <= args.max;
      },
      params: ['min', 'max'],
      message: (fieldName, placeholders) => `此欄位限制最少為 ${placeholders.min} 個字，最多為 ${placeholders.max} 個字`
    });

    extend('confirmed', {
      ...confirmed,
      message: '新密碼確認不一致，請在 \"新密碼\" 與 \"再輸入一次新密碼\" 欄位中，重複輸入一樣的新密碼，並請不要用複製貼上的方式，以便確認您沒有把新密碼輸入錯誤'
    })

    // extend('userid', {
    //   validate: userIdCheckAsync,
    //   message: "會員帳號 {_value_} 已經有人使用，請更換其他的會員帳號"
    // });
    extend('userid', userIdCheckAsync);

    extend('email-check', emailCheckAsync);
  }
}

module.exports = VeeValidate