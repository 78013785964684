const _ = require('lodash')

const watchValue = (condition, interval, times) => {
  return new Promise((resolve, reject) => {
    let check = function (times) {
      let conditionResult = condition()
      //console.log('watch-mixin', 'typeof', typeof (conditionResult), conditionResult, times);

      if (typeof (conditionResult) == 'boolean' && conditionResult == true) {
        resolve()
        return
      }
      else if (typeof (conditionResult) == 'object' && _.has(conditionResult, 'result')) {
        //console.log('watch-mixin', 'conditionResult', conditionResult)
        resolve(conditionResult)
      }

      times -= 1
      if (times == 0) {
        reject('timeout')
        return
      }

      setTimeout(function () {
        check(times)
      }, interval)
    }

    check(times)
  })
}
const WatchMethod = function (condition, interval, timeout) {
  return new Promise((resolve, reject) => {
    let times = _.ceil(timeout / interval)
    watchValue(condition, interval, times)
      .then(resolve)
      .catch(reject)
  })
}

const WatchMixin = {
  methods: {
    watch: WatchMethod
  }
}

module.exports = WatchMixin