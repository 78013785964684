<template>
  <div class="container my-container">
    <vue-inet-content
      :api_name="'Shop-Cart'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
    <sweet-modal
      ref="payment-modal"
      modal-theme="dark"
      overlay-theme="dark"
      :blocking="blocking_modal"
      width="80%"
      height="80%"
      v-on:close="paymentModalClosed"
    >
      <iframe :src="payment_url" frameborder="0" allowfullscreen></iframe>
    </sweet-modal>
    <v-tour name="myTour" :steps="steps"></v-tour>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";

const api = require("@/api");

export default {
  data() {
    return {
      metadata: {
        ShoppingCart: this.$sessionStorage.get("shopping-cart", []),
        showPaymentModal: this.showPaymentModal,
        remove: this.removeItemFromCart,
        next: this.next,
      },
      metadata_ready: false,
      stopCheckingCart: false,
      payment_url: "",
      blocking_modal: true,
      watch_modal_url: false,
      sce_payment_order_id: "",
      steps: [
        {
          target: "#table-products", // We're using document.querySelector() under the hood
          header: {
            title: "瀏覽介紹",
          },
          content: `如果表格沒有辦法完整呈現，您可以單指左右滑動這個表格，看到無法完整呈現的資訊`,
        },
      ],
    };
  },
  computed: {
    // ...mapGetters(["ShoppingCart"]),
    // ShoppingCart() {
    //   return this.$sessionStorage.get("shopping-cart", []);
    // },
    PaymentModal() {
      if (this.$refs["payment-modal"]) {
        return this.$refs["payment-modal"];
      }

      return null;
    },
    PaymentModalUrl() {
      if (this.watch_modal_url == false) return null;

      let $el = this.PaymentModal.$el;

      var iframes = $el.getElementsByTagName("iframe");
      if (iframes.length > 0) {
        var $iframe = iframes[0];

        if ($iframe.attributes["src"]) {
        }

        //debugger;

        //console.log($iframe, $iframe.attributes["src"]);

        return $iframe.attributes["src"];
      }
    },
  },
  methods: {
    watchModalUrl() {
      if (this.watch_modal_url == false) return;

      let src = this.PaymentModalUrl;

      //console.log("PaymentModalUrl : ", src);

      //setTimeout(this.watchModalUrl, 100);
    },
    showPaymentModal() {
      if (this.PaymentModal) {
        let _this = this;

        //this.watch_modal_url = true;
        //this.watchModalUrl();

        setTimeout(function() {
          //_this.payment_url = e.srcElement.href;
          //this.payment_url = url;
          _this.PaymentModal.open();
          _this.watch_modal_url = true;
        }, 250);
        setTimeout(this.watchModalUrl, 3000);
      }

      return false;
    },
    paymentModalClosed() {
      this.watch_modal_url = false;
      //console.log('paymentModalClosed !');
      //this.refreshOrders();
      this.$router.push({ name: "MemberCenter" });

      // this.$swal({
      //   icon: "success",
      //   text: "請點選訂單編號進行結帳程序",
      //   showConfirmButton: true,
      //   position: "top-end",
      // });
    },
    closePaymentModal() {
      if (this.PaymentModal) {
        this.PaymentModal.close();
      }
    },
    checkIsCartEmpty: function() {
      let _this = this;
      const checkIsEmpty = function() {
        if (_this.$sessionStorage.get("shopping-cart", []).length == 0) {
          //this.$router.push({ name: "Shop" });
          return true;
        }

        return false;
      };

      const check = function() {
        if (_this.stopCheckingCart) return;

        if (checkIsEmpty() == true) {
          if (_this.stopCheckingCart) return;

          _this.$router.push({ name: "Shop" });
          return;
        }

        //setTimeout(check, 200);
      };

      check();
    },
    removeItemFromCart: function($item) {
      var cart = this.$sessionStorage.get("shopping-cart", []);

      cart = _.remove(cart, function(item) {
        return item.item_id != $item.item_id;
      });

      this.$nextTick(function() {
        this.$sessionStorage.set("shopping-cart", cart);
      });

      this.$router.push({ name: "Shop" });
    },
    next: async function($event) {
      $event.preventDefault();
      $event.stopPropagation();

      var cart = this.currentMetadata.ShoppingCart;
      if (cart.length == 0) {
        return false;
      }

      if (cart && cart.length > 0 && cart[0].is_check_member_data) {
        this.$router.push({ name: "Shop3" });
        return;
      }

      var $item = cart[0];
      //console.log($item.item_id);

      var payload = {
        item_id: $item.item_id,
      };

      this.showLoading("create-order");

      var result = await api.CreateOrder(payload);

      this.hideLoading("create-order");

      if (result.status_code == 200) {
        var $data = result.result.data;
        var sce_payment_order_id = $data.sce_payment_order_id;

        this.sce_payment_order_id = sce_payment_order_id;

        let url = `https://scepayment.sce.pccu.edu.tw/payweb/info/?orderid=${sce_payment_order_id}`;
        this.payment_url = url;
        this.showPaymentModal();
        //window.open(url, "_blank");

        this.stopCheckingCart = true;

        this.clearShoppingCart();
      }

      return false;
    },
  },
  mounted() {
    let _this = this;

    if (this.isLogin == false) {
      this.$swal({
        icon: "warning",
        text: "請先登入會員，才可繼續完成購買流程",
        backdrop: false,
        position: "top-end",
        //timer: 8000,
      });
      this.$emit("redirectTo", { name: "MemberLogin" });

      return false;
    }

    // setTimeout(function() {
    //   _this.$tours["myTour"].start();
    // }, 2000);

    this.checkIsCartEmpty();
    this.metadata.ShoppingCart = this.$sessionStorage.get("shopping-cart", []);
    this.metadata_ready = true;

    // console.log(this.currentShoppingCart);
    //this.showModal();
  },
};
</script>
