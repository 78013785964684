const AppHeightFixMixin = {
  data() {
    return {
      __prev_height: 0,
    }
  },
  methods: {
    watchHeightAndFix() {
      if ($) {
        var $app = $('#app')
        var $el = $('nav[role="navigation"]');

        if ($el.length > 0) {
          if ($el.height() != this.__prev_height) {
            $app.height($el.height())
            this.__prev_height = $app.height()
          }
        }
      }

      setTimeout(this.watchHeightAndFix, 250)
    }
  },
  mounted() {
    setTimeout(this.watchHeightAndFix)
  }
}

module.exports = AppHeightFixMixin