<template>
  <div class="image-carousel">
    <div v-if="imageCount == 1">
      <img
        class="img-responsive img-rounded"
        :src="generateImageUrl(items[0])"
      />
    </div>
    <div v-if="imageCount > 1">
      <swiper
        :ref="swiperName"
        :swiper-key="swiperKey"
        :options="swiperOptions"
      >
        <swiper-slide v-for="(item, $index) in items" v-bind:key="$index"
          ><img
            class="img-responsive img-rounded"
            :class="{ link: getUrl(item).length > 0 }"
            @click="click(item, $event)"
            :src="generateImageUrl(item)"
            :title="item.TitleC"
          />
        </swiper-slide>
        <div
          class="swiper-pagination"
          :api-name="api"
          :swiper-key="swiperKey"
          slot="pagination"
        ></div>
        <div
          class="swiper-button-prev"
          :api-name="api"
          :swiper-key="swiperKey"
          slot="button-prev"
          @click="paginationClick('prev')"
        ></div>
        <div
          class="swiper-button-next"
          :api-name="api"
          :swiper-key="swiperKey"
          slot="button-next"
          @click="paginationClick('next')"
        ></div>
      </swiper>
    </div>
  </div>
</template>
<script>
const { v4: uuidv4 } = require("uuid");
const _ = require("lodash");
const $ = require("jquery");

const api = require("@/api");
const config = require("@/config");

import { WatchMixin, ImageCarouselMixin } from "@/mixins";

const _swiperKey = uuidv4();

export default {
  data() {
    return {
      imageCount: 0,
      carouselItems: [],
      swiperKey: _swiperKey,
      swiperOptions: {
        pagination: {
          el: `.swiper-pagination`,
          clickable: true,
        },
        navigation: {
          nextEl: `.swiper-button-next[api-name="${this.api_name}"][swiper-key="${_swiperKey}"]`,
          prevEl: `.swiper-button-prev[api-name="${this.api_name}"][swiper-key="${_swiperKey}"]`,
        },
        autoplay: {
          delay: 5000,
        },
        // Some Swiper option/callback...
      },
    };
  },
  props: ["api_name"],
  mixins: [WatchMixin, ImageCarouselMixin],
  computed: {
    swiper() {
      return this.$refs[this.swiperName].$swiper;
    },
    swiperName() {
      return `swiper-${this.api_name}-${this.swiperKey}`;
    },
    // swiperKey() {
    //   return uuidv4();
    // },
    items() {
      return this.carouselItems;
    },
    api() {
      return this.api_name;
    },
  },
  methods: {
    getUrl(item) {
      if (_.isString(item["URL"])) {
        return item["URL"];
      }
      return "";
    },
    getImage(item) {
      let imageUrl = this.generateImageUrl(item);

      return `url(${imageUrl}) !important`;
    },
    generateImageUrl(image) {
      let imageUrl = `${config["inet-host"]}/download.ashx?FileID=${image.ImageID}&FileName=${image.ImageName}`;

      return imageUrl;
    },
    paginationClick(direction) {
      // console.log("paginationClick ", direction);
    },
    clickPrev() {
      // console.log(
      //   "clickPrev...",
      //   this.swiper.isBeginning,
      //   this.swiper.activeIndex,
      //   this.swiper.slides
      // );
      if (this.swiper.isBeginning) {
        let length = this.swiper.slides.length;
        this.swiper.slideToLoop(length - 1);
        return;
      }
      this.swiper.slidePrev();
    },
    clickNext() {
      // console.log(
      //   "clickNext...",
      //   this.swiper.isEnd,
      //   this.swiper.activeIndex,
      //   this.swiper.slides
      // );
      if (this.swiper.isEnd) {
        this.swiper.slideToLoop(0);
        return;
      }
      this.swiper.slideNext();
    },
    async loadFromApi(apiName) {
      //console.log(apiName);
      if (apiName && apiName !== "") {
        let url = config["inet-api"][apiName];
        let result = await api.GetList(url);

        if (result && result["iBoardList"]) {
          //this.content = result["iBoard"]["ContentC"];
          let boards = result["iBoardList"];
          let board = boards.iBoard;
          //console.log("iNetImageCarousel.vue", board.length);
          let imageCount = board.length || 1;
          this.imageCount = imageCount;

          this.carouselItems = [];

          if (imageCount == 1) {
            // http://inet.scenet.net/Download.ashx?FileID=thchang_20201015183728&amp;FileName=footer-logo.png
            this.carouselItems = [board];
          } else if (imageCount > 1) {
            let _this = this;

            //console.log("board", board);

            board.forEach((item) => {
              this.carouselItems.push(item);
            });

            let checkCondition = function() {
              let refName = _this.swiperName;

              let check = _this.$refs[refName] !== undefined;
              // console.log(
              //   "from",
              //   `iNetIamgeCarousel2.${refName}`,
              //   "_this.$refs",
              //   _this.$refs,
              //   "refName",
              //   refName
              // );
              if (check == true) {
                return {
                  result: true,
                  watch: _this.$refs[refName],
                  from: `iNetIamgeCarousel2.${refName}`,
                };
              }
              return false;
            };
            this.watch(checkCondition, 100, 5000)
              .then(function(result) {
                let swiper = _this.swiper;
                if (typeof result == "object") {
                  swiper = result.watch.$swiper;
                }

                _this.carouselImageReady(); // this method in mixin

                swiper.autoplay.start();

                let $prevEl = $(_this.swiperOptions.navigation.prevEl);
                let $nextEl = $(_this.swiperOptions.navigation.nextEl);

                $prevEl.click(_this.clickPrev);
                $nextEl.click(_this.clickNext);
              })
              .catch(function(ex) {
                console.log("iNetImageCarousel2.vue", "watch #2", "ex", ex);
              });
          }
        }
        //console.log("Image Carousel.vue", result);
      }
    },
    click(item, e) {
      e.preventDefault();
      e.stopPropagation();

      let url = this.getUrl(item);
      if (url.length == 0) return false;
      window.open(url, "_blank");
    },
  },
  mounted() {
    let _this = this;
    let checkCondition = function() {
      return _.isString(_this.api_name) && _this.api_name != "";
    };
    this.watch(checkCondition, 100, 3000)
      .then(function() {
        _this.loadFromApi(_this.api_name);
      })
      .catch(function() {
        console.log("iNetImageCarousel2.vue", "watch #1", "error");
      });
  },
};
</script>
