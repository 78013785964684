<template>
  <div class="container my-container">
    <vue-inet-content
      :api_name="'TryFilm'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
const moment = require("moment");
const _ = require("lodash");

const config = require("@/config");
const api = require("@/api");

export default {
  data() {
    return {
      metadata: {
        channel: "",
        year: 0,
        month: 0,
        day: 0,
        date: null,
        media: null,
        section: "1",
        gotoSection: this.gotoSection,
        getMediaSrc: this.getMediaSrc,
        rightClick: this.rightClick,
      },
      metadata_ready: false,
    };
  },
  methods: {
    rightClick($event) {
      $event.preventDefault();
      $event.stopPropagation();

      return false;
    },
    getMediaSrc(media, section) {
      if (_.has(media, `media_section${section}`)) {
        return media[`media_section${section}`];
      }
      return "";
    },
    gotoSection(channel, section) {
      let routerItem = {
        name: "FreeFilmChannel",
        params: channel,
        query: { section },
      };
      var path = this.$router.resolve(routerItem).href;

      location.href = path;

      // this.$router.push({
      //   name: "FreeFilmChannel",
      //   params: { channel: this.channel },
      //   query: { section },
      // });

      //this.$emit("redirectTo", routerItem);
    },
  },
  computed: {
    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
    hasDateParams() {
      if (
        _.has(this.routeParams, "year") &&
        _.has(this.routeParams, "month") &&
        _.has(this.routeParams, "day")
      ) {
        return true;
      }
      return false;
    },
    year() {
      if (_.has(this.routeParams, "year")) {
        return parseInt(this.routeParams["year"]);
      }
      return parseInt(moment().format("YYYY"));
    },
    month() {
      if (_.has(this.routeParams, "month")) {
        return parseInt(this.routeParams["month"]);
      }
      return parseInt(moment().format("MM"));
    },
    day() {
      if (_.has(this.routeParams, "day")) {
        return parseInt(this.routeParams["day"]);
      }
      return parseInt(moment().format("DD"));
    },
    verifyToken() {
      var value = vue.$sessionStorage.get("jwt-ot-token", null);

      return value;
    },
    section() {
      if (_.has(this.querys, "section")) {
        return this.querys.section;
      }
      return "1";
    },
  },
  mounted() {
    //console.log("this.channel", this.channel);

    var defaultChannel = config["default-channel"];
    if (this.channel == "") {
      this.$router.push({
        name: "FreeFilm",
        params: { channel: defaultChannel },
      });
      return;
    }

    var $date = moment();
    var year = this.hasDateParams ? this.year : $date.format("YYYY");
    var month = this.hasDateParams ? this.month : $date.format("MM");
    var day = this.hasDateParams ? this.day : $date.format("DD");
    var date = $date.format("YYYY-M-D");
    if (this.hasDateParams) {
      date = moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format("YYYY-M-D");
    }

    // console.log(`${year}-${month}-${day}`);
    // console.log(date);

    this.metadata.year = year;
    this.metadata.month = month;
    this.metadata.day = day;
    this.metadata.date = date;
    this.metadata.section = this.section;

    let _this = this;

    //console.log("this.section : ", this.section);
    this.showLoading("get-free-media");
    api.GetFreeMedia(this.channel, date).then((response) => {
      this.hideLoading("get-free-media");
      if (response.status_code == 200) {
        let $result = response.result;
        //console.log("$result", $result);
        _this.metadata.media = $result;
        _this.metadata.channel = _this.channel;

        _this.metadata_ready = true;
      }
    });
  },
};
</script>
