const getObserveValue = function (observeData) {
  // console.log('metadata-mixin.js',
  //   'observeData',
  //   observeData)
  return JSON.parse(JSON.stringify(observeData));
};

const getIsObserve = function (data) {
  return data.hasOwnProperty("__ob__");
};

const getIsAvailable = function (obj, propertyName) {
  if (typeof (obj) == 'object') return true;

  return false;
};

// const WatchMixin = require('./watch-mixin')

const MetadataMixin = {
  computed: {
    currentMetadata: function () {
      if (getIsObserve(this.metadata)) {
        return getObserveValue(this.metadata);
      }
      return this.metadata;
    }
  },
  methods: {
    getObserveValue,
    getIsObserve,
    get: (data, propertyName) => {
      var _data = null;
      if (getIsObserve(data)) {
        _data = getObserveValue(data);
      }
      else if (data.hasOwnProperty(propertyName)) {
        _data = data;
      }

      if (_data &&
        typeof (_data) == 'object' &&
        _data.hasOwnProperty(propertyName)) {
        return _data[propertyName];
      }

      return undefined;
    },
    getCurrentMetadata: function (_this) {
      if (getIsObserve(_this.metadata)) {
        return getObserveValue(_this.metadata);
      }
      return _this.metadata;
    }
  },

  // mixins: [WatchMixin],

  // mounted() {

  // },

}

module.exports = MetadataMixin