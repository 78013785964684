<template>
  <div class="qa-block">
    <h3 v-text="title"></h3>
    <div class="row qa" v-for="(item, $index) in items" v-bind:key="$index">
      <div class="col-12 qa-item">
        <div class="qa-col qa-icon"><i class="fas fa-chevron-down"></i></div>
        <div class="qa-col qa-title" @click="clickItem(item, $index)">
          {{ `${item.TitleC}` }}
        </div>
      </div>
      <slide-up-down
        :ref="`info-${$index}`"
        :set-class="'qa-info'"
        :content="item['ContentC']"
      >
      </slide-up-down>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { GetiNetListMixin } from "../../mixins";
//import SlideUpDown from "vue-slide-up-down";
import SlideUpDown from "../SlideUpDown.vue";

export default {
  data() {
    return {
      items_expand: {},
    };
  },
  mixins: [GetiNetListMixin],
  methods: {
    toggleExpandStatus(index) {
      var key = `item-${index}`;

      if (_.has(this.items_expand, key) == false) {
        this.items_expand[key] = false;
      } else if (_.isBoolean(this.items_expand[key]) == false) {
        this.items_expand[key] = false;
      }
      this.items_expand[key] = !this.items_expand[key];
      //console.log(this.items_expand[key]);
    },
    clickItem(item, index) {
      //this.toggleExpandStatus(index);
      var refKey = `info-${index}`;
      var target = this.$refs[refKey][0];

      if (target["toggleShow"]) {
        target.toggleShow();
      }
    },
  },
  computed: {
    expandStatus() {
      return this.items_expand;
    },
  },
  components: { SlideUpDown },
};
</script>
