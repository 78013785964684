<template>
  <div class="container my-1th-container">
    <vue-inet-content
      :api_name="'Member-Register-Form'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import App from "@/App";

const api = require("../api");

export default {
  data() {
    return {
      metadata: {
        form: {
          name: "",
          account: "",
          password: "",
          confirm_password: "",
          id_number: "",
          phone: "",
          email: "",
          address: "",
        },
        next: this.next,
      },
      metadata_ready: true,
    };
  },
  methods: {
    ...mapActions(["setRegisterEmail", "setIsLoading"]),

    async next() {
      this.setIsLoading(true);

      //var form = this.get(this.metadata, "form");
      console.log("MemberRegister2.vue", "next()");
      console.log(
        "MemberRegister2.vue",
        "next()",
        "this.currentMetadata :",
        this.currentMetadata,
        "this.currentMetadata.form :",
        this.currentMetadata.form
      );

      var postdata = {
        form: this.currentMetadata.form,
      };

      var result = await api.CreateUser(postdata);

      this.setIsLoading(false);

      if (result && result.status_code == 200) {
        this.setRegisterEmail(this.currentMetadata.form.email);

        this.$router.push({ name: "MemberRegisterVerify" });
        return;
      }
    },
  },
  mounted() {
    //App.$emit("loading");
  },
  beforeDestroy() {},
};
</script>
