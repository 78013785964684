const _ = require("lodash");
const api = require("../api");
const config = require("../config");

// import WatchMixin from "./watch-mixin"
const WatchMixin = require("./watch-mixin")

const GetListMixin = {
  data() {
    return {
      title: "",
      items: [],
    };
  },
  props: ["api_name"],
  mixins: [WatchMixin],
  methods: {
    async loadFromApi(apiName) {
      //console.log(apiName);
      if (apiName && apiName !== "") {
        let url = config["inet-api"][apiName];
        let result = await api.GetList(url);
        //console.log("iNetList", "result", result);

        if (result && result["iBoardList"]) {
          let boards = result["iBoardList"];
          this.title = boards["@BlockName"];
          this.items = this.items.concat(boards.iBoard);
        }

        //console.log("this.items", this.items);
      }
    },
  },
  mounted() {
    let _this = this;
    let checkCondition = function () {
      return _.isString(_this.api_name) && _this.api_name != "";
    };
    this.watch(checkCondition, 100, 3000)
      .then(function () {
        _this.loadFromApi(_this.api_name);
      })
      .catch(function () {
        //console.log("GetListMixin", "watch #3", "error");
      });
  },
}

module.exports = GetListMixin
