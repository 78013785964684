const _ = require("lodash");

const config = require("@/config");

const defaultLoadingTimeoutInSeconds = 15;

let activeLoadingDone = false;
let keys = [];
let timeoutId = 0;

const LoadingMixin = {
  // props: {
  //   isLoadingDone: Boolean
  // },
  data() {
    return {
      activeKeys: [],
      $isLoadingDone: false,
    }
  },
  computed: {
    // loadingDone: function () {

    // },
  },
  methods: {
    // ToJArray(obj) {
    //   var jarray = []
    //   for (const [key, value] of Object.entries(object1)) {

    //   }
    // }
    loadingTimeout: function () {
      this.$emit("loading", false, '', true);
    },
    showLoading: function (key) {
      let _keys =
        this.getObserveValue(this.activeKeys);
      let $index = _keys.indexOf(key);
      if ($index == -1) {
        _keys.push(key);
      }
      this.$emit("loading", true, key);

      activeLoadingDone = true;

      this.activeKeys = _keys;
      // console.log("loading-mixin", "keys : ", keys)

      let loadingTimeoutInSeconds = config["loadingTimeout"]
      if (_.isString(loadingTimeoutInSeconds)) {
        try {
          loadingTimeoutInSeconds = parseInt(loadingTimeoutInSeconds)
        }
        catch {
          loadingTimeoutInSeconds = defaultLoadingTimeoutInSeconds;
        }
      }
      if (_.isInteger(loadingTimeoutInSeconds) == false) {
        loadingTimeoutInSeconds = defaultLoadingTimeoutInSeconds;
      }

      let loadingTimeoutValue =
        loadingTimeoutInSeconds * 1000;

      if (timeoutId != 0) {
        clearTimeout(timeoutId);
        timeoutId = 0;
      }

      timeoutId =
        setTimeout(this.loadingTimeout, loadingTimeoutValue)
    },
    hideLoading: function (key) {
      let _keys =
        this.getObserveValue(this.activeKeys);
      let $index = _keys.indexOf(key);
      if ($index != -1) {
        this.$emit("loading", false, key);
        _keys = _.remove(_keys, key);
      }

      this.activeKeys = _keys;
      // console.log("loading-mixin", "keys : ", keys)

      // if (this.activeKeys.length == 0) {
      //   activeLoadingDone = false;
      // }
    },
  },
  watch: {
    // isloadingDone: function (value, oldValue) {
    //   console.log("GlobalMixin", "loadingDone", value, oldValue)
    // }
    activeKeys: function (value, prevValue) {
      value = this.getObserveValue(value);
      prevValue = this.getObserveValue(prevValue);

      // console.log(
      //   "loading-mixin.js",
      //   "activeKeys",
      //   value,
      //   prevValue)

      // console.log(
      //   "loading-mixin.js",
      //   "activeKeys",
      //   value)

      if (activeLoadingDone == false) {
        this.$isLoadingDone = false;
      }

      //return this.$attrs;
      this.$isLoadingDone = (value.length == 0);
    },

    // loadingDone: function (value, prevValue) {
    //   console.log(
    //     "loading-mixin.js",
    //     "isloadingDone",
    //     value,
    //     prevValue)
    // },
  }
}

module.exports = LoadingMixin