const WatchMixin = require('./watch-mixin')
const GetiNetListMixin = require('./get-inet-list-mixin')
const AppHeightFixMixin = require('./app-height-fix-mixin')
const ImageCarouselMixin = require('./image-carousel-mixin')
const RouteMixin = require('./route-mixin')
const MetadataMixin = require('./metadata-mixin')
const LoginMixin = require('./login-mixin')
const ShoppingCartMixin = require('./shopping-cart-mixin')
const LoadingMixin = require("./loading-mixin")
const SweetAlert2Mixin = require("./sweetalert2-mixin")
const PWAUpdateMixin = require("./pwa-update-mixin")
const GlobalMixin = require("./global-mixin")

export {
  WatchMixin,
  GetiNetListMixin,
  AppHeightFixMixin,
  ImageCarouselMixin,
  RouteMixin,
  MetadataMixin,
  LoginMixin,
  ShoppingCartMixin,
  LoadingMixin,
  SweetAlert2Mixin,
  PWAUpdateMixin,
  GlobalMixin
}