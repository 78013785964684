<template>
  <div class="container my-container">
    <vue-inet-content
      ref="shop"
      :api_name="'Shop'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
const _ = require("lodash");
const moment = require("moment");

const api = require("@/api");
const config = require("@/config");

export default {
  data() {
    return {
      metadata: {
        ShoppingCart: this.$sessionStorage.get("shopping-cart", []),
        activeId: "",
        activeItem: null,
        groups: [],
        hasSelected: false,

        find: this.findItemByChannel,
        findItemId: this.findItemIdByChannel,
        click: this.clickShopItem,
        next: this.next,
      },
      metadata_ready: false,
      nextMonthOrders: [],
    };
  },
  computed: {
    hasSelected() {
      return this.metadata.activeItem != null;
    },
  },
  methods: {
    //...mapActions(["addToShoppingCart"]),

    addToShoppingCart: function($item) {
      var cart = this.$sessionStorage.get("shopping-cart", []);

      cart.push($item);

      this.$sessionStorage.set("shopping-cart", cart);
    },
    next: function(group) {
      // console.log(
      //   "Shop.vue",
      //   "next()",
      //   "this.metadata.activeItem :",
      //   this.metadata.activeItem
      // );
      if (group.hasSelected == false) {
        return false;
      }

      if (this.metadata.activeItem == null) {
        this.clearShopItem();

        group.has_channel_not_select_error = true;
        this.$swal({
          icon: "question",
          text: "請選擇要購買的產品",
          showConfirmButton: false,
          backdrop: false,
          position: "top-end",
          timer: 3000,
        });
        return;
      }

      this.addToShoppingCart(this.metadata.activeItem);

      this.$router.push({ name: "Shop2" });
    },
    clearShopItem: function() {
      this.metadata.activeId = "";
      this.metadata.activeItem = null;
      this.metadata.groups.forEach((group) => {
        group["hasSelected"] = false;
        group["has_channel_not_select_error"] = false;
      });
    },
    clickShopItem: function($event, $item, $group) {
      //console.log($item, $group);

      let $nextMonthOrders = this.getObserveValue(this.nextMonthOrders);

      let findIndex = _.findIndex($nextMonthOrders, {
        channel: $item.fee_channel,
      });

      if (findIndex > -1) {
        this.$swal({
          icon: "warning",
          text:
            "您目前已經購買此頻道產品，請於訂購有效期間的最後一個月再進行購買",
          backdrop: false,
          showConfirmButton: false,
          position: "top-end",
          timer: 2000,
        });
        return;
      }

      this.clearShopItem();

      $item["display_title"] = $group.display_title;
      $item["start_date"] = $group.start_date;
      // TODO: 這裡之後可能會有問題，當同一個群組中的不同商品有不同價格時，
      // 應該要可以抓到每一項商品的金額，而非只有紀錄群組的最多與最少金額
      $item["price"] = $group.last_price;

      this.metadata.activeId = $item.item_id;
      this.metadata.activeItem = $item;

      // console.log(this.$refs);

      this.metadata.hasSelected = true;

      $group.hasSelected = true;

      this.$refs["shop"].forceUpdate();
      // this.metadata.activeId = id;
      // console.log("Shop.vue", "clickShopItem", "id = ", id);
      // console.log(this.$forceUpdate);
    },
    findItemIdByChannel: function(items, findChannel) {
      let item = this.findItemByChannel(items, findChannel);
      if (item == null) return "";

      let $nextMonthOrders = this.getObserveValue(this.nextMonthOrders);

      let findIndex = _.findIndex($nextMonthOrders, {
        channel: findChannel,
      });

      if (findIndex == -1) return item.item_id;

      return null;
    },
    findItemByChannel: function(items, findChannel) {
      let findIndex = _.findIndex(items, { fee_channel: findChannel });

      if (findIndex == -1) return null;

      let item = items[findIndex];
      //item["find-id"] = findChannel;
      return item;
    },
    getShopItems: async function() {
      let postdata = config["digichannel-api"]["ShopItems"];
      this.showLoading("get-shop-items");

      let response = {};
      try {
        response = await api.GetShopItems(postdata);
      } catch (ex) {}

      this.hideLoading("get-shop-items");

      if (_.has(response, "status_code") && response["status_code"] == 200) {
        //console.log(response.result.groups);

        // all start
        var $date = moment().set("date", 1);
        //console.log("$date", $date.format("YYYY/M/D"));

        var month = $date.month();

        for (var i = 1; i < 32; i++) {
          $date = $date.add(1, "days");
          if ($date.month() != month) {
            break;
          }
        }
        let $first = $date.format("YYYY/M");
        //console.log($first);

        let $groups = response.result.groups;

        $groups.forEach((group) => {
          $date = moment().set("date", 1);
          group.start_date = $first;
          group.has_channel_not_select_error = false;
          var $lastDay = $date.add(group.months + 1, "months").add(-1, "days");
          //console.log("$lastDay", $lastDay.format("YYYY/M/D"));

          group.end_date = $lastDay.format("YYYY/M");
          let itemExistsCount = 0;
          group.items.forEach((item) => {
            let $fee_channel = item.fee_channel;
            if (_.has(this.nextMonthOrders, $fee_channel)) {
              item["exists"] = true;
              itemExistsCount += 1;
            }
          });
        });

        this.metadata.groups = response.result.groups;

        //console.log("Shop.vue", "this.metadata.groups :", this.metadata.groups);
        this.metadata_ready = true;
        this.clearShopItem();
      }
    },
  },
  async mounted() {
    this.showLoading("check-next-month-available");
    let response = {};
    try {
      if (this.isLogin) response = await api.GetPaidOrders();
    } catch (ex) {}
    this.hideLoading("check-next-month-available");
    //debugger;
    var orders = null;
    var order_channels = [];

    if (response.status_code == 200) {
      orders = response.result;
    }
    if (orders && orders.length > 0) {
      let $date = moment().add(1, "months");
      let $year = $date.year();
      let $month = $date.month() + 1;

      orders.forEach((order) => {
        //if (isNextMonthStillAvailable == true) return;
        if (_.has(order_channels, order.channel)) return;

        let isNextMonthStillAvailable = false;
        let $nextMonthOrder = null;

        let $available_months = order.available_months;

        $available_months.forEach((month) => {
          if (isNextMonthStillAvailable == true) return;

          let $start = month.start;
          let $end = month.end;
          if (
            $start.year == $year &&
            $end.year == $year &&
            $start.month == $month &&
            $end.month == $month
          ) {
            isNextMonthStillAvailable = true;
            $nextMonthOrder = order;
          }
        });

        if (isNextMonthStillAvailable == true) {
          // order_channels[order.channel] = {
          //   next_month_available: true,
          //   next_month: $nextMonthOrder,
          // };
          order_channels.push({
            channel: order.channel.toLowerCase(),
            order: order,
          });
        }
      });

      // console.log("Shop.vue", "check next month is still available ?");
      // console.log("order_channels", order_channels);
      this.nextMonthOrders = order_channels;
    }

    var cart = this.currentMetadata.ShoppingCart;
    if (cart.length > 0) {
      this.$router.push({ name: "Shop2" });
      return;
    }

    this.getShopItems();
  },
};
</script>
