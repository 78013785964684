const $ = require('jquery')

const VueBodyClassname = {
  install: function (Vue) {
    Vue.directive('body-classname', {
      bind: function (el, binding) {
        //console.log('VueBodyClassname', 'bind', el, binding)
        //var $el = $(el)
        let addClassname = function ($el, className) {
          $el.removeClass(className).addClass(className);
        };
        $(function () {
          let $body = $(document.body);
          
          if (Array.isArray(binding.value)) {
            binding.value.forEach(function (className) {
              addClassname($body, className);
            });
          }
          else {
            $body.removeClass(binding.value).addClass(binding.value);
          }
        })
      },
      update: function (el, binding) {

      },
    })
    Vue.directive('body-classname-remove', {
      bind: function (el, binding) {
        //console.log('VueBodyClassname', 'bind', el, binding)
        //var $el = $(el)
        let removeClassname = function ($el, className) {
          $el.removeClass(className);
        };

        $(function () {
          let $body = $(document.body);
          
          if (Array.isArray(binding.value)) {
            binding.value.forEach(function (className) {
              removeClassname($body, className);
            });
          }
          else {
            $body.removeClass(binding.value);
          }
        })
      },
      update: function (el, binding) {

      },
    })
  }
}

module.exports = VueBodyClassname