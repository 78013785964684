<template>
  <span>
    <h3>{{ title }}</h3>
    <div
      class="row my-underline"
      v-for="(item, $index) in items"
      v-bind:key="$index"
    >
      <div class="col-sm-2 col-md-3">
        <p class="my-text-date" v-text="item.PostDate"></p>
      </div>
      <div class="col-sm-10 col-md-9">
        <p class="text-justify" v-html="item.TitleC"></p>
      </div>
    </div>
  </span>
</template>
<script>
import { GetiNetListMixin } from "../../mixins";

export default {
  mixins: [GetiNetListMixin],
};
</script>
