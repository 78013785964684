<template>
  <div class="container my-1th-container" v-body-classname="'primary-body'">
    <vue-inet-content
      :api_name="'Member-Login'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
// @ is an alias to /src
import _ from "lodash";
import Vue from "vue";
const axios = require("axios");
const api = require("@/api");

export default {
  name: "MemberLogin",
  data() {
    return {
      metadata: {
        account: "",
        password: "",
        login: this.login,
      },
      metadata_ready: true,
    };
  },
  methods: {
    afterLogin(response) {
      //console.log("MemberLogin", "response : ", response);
      this.hideLoading("login");

      if (response) {
        var result = response.result;
        //console.log("MemberLogin", "result : ", result);

        if (response.status_code == 200) {
          var token = result.access_token;
          var secret = result.secret;

          this.$sessionStorage.set("jwt-token", { token, secret });

          // let mock_jwt = {
          //   token:
          //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJoc3lodWFuZyIsIm5hbWUiOiLpu4Ppoa_pgaAiLCJleHAiOjE2MDgyNTkyMzcsIm5iZiI6MTYwODA4NjQzNywiaWF0IjoxNjA4MDg2NDM3LCJpc3MiOiJhNDg3NjNjZi03Njk2LTRiMGItOGJmOC1hN2U1ZGRmMDY1NDgiLCJhdWQiOiJoc3lodWFuZyJ9.wbWSwxpG91mO8r84VLvoWN_z3Xp7Lew6YHviXl4Jm-g",
          //   secret: "8UZFcltmlIt1JQbzaY16FZxXsoGdqVPybbfeR2dmFQI",
          // };
          //this.$sessionStorage.set("mock-jwt-token", mock_jwt);

          //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          //this.$router.push({ name: "Film" });
          // var path = this.$router.resolve({ name: "Film" }).href;

          let _this = this;

          const checkIsLogin = function() {
            //console.log("_this.isLogin", _this.isLogin);
            return _this.isLogin;
          };

          const nextStep = () => {
            _this
              .$swal({
                icon: "success",
                text: "登入成功 !",
                showConfirmButton: false,
                position: "top-end",
                timer: 2000,
              })
              .then(() => {
                var path = _this.$router.resolve({ name: "Film" }).href;
                // this.$router.push({ name: "Film" });
                location.href = path;
                // _this.$emit("redirectTo", { name: "Film" });
              });
            // _this.$emit("redirectTo", { name: "Film" });
          };

          const check = function() {
            if (checkIsLogin()) {
              return nextStep();
            }

            setTimeout(check, 100);
          };

          //check();
          setTimeout(nextStep, 1000);
        } else {
          if (_.has(result, "data")) {
            let $data = result.data;
            if (_.has($data, "is_not_valid") && $data.is_not_valid == true) {
              // account not valid
              this.$swal({
                icon: "error",
                text:
                  "您的帳號尚未完成驗證，請先依照畫面指示操作，完成驗證程序",
                showConfirmButton: true,
                backdrop: false,
                position: "top-end",
                //timer: 10000,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> 知道了',
              });
              this.$router.push({ name: "MemberRegisterVerify" });
              return;
            }
          }

          let message = "";

          if (_.has(result, "data")) {
            let $data = result.data;

            if (_.has($data, "message")) {
              message = `${$data.message} (${result.result_number})`;
            } else {
              message = `${$data} (${result.result_number})`;
            }
          }

          this.$swal({
            icon: "error",
            text: message,
          });
        }
      }
    },
    async login() {
      //this.$emit("loading", true)
      this.showLoading("login");

      var postdata = {
        account: this.currentMetadata.account,
        password: this.currentMetadata.password,
      };

      try {
        var response = await api.Login(postdata);
        this.afterLogin(response);
      } catch (ex) {
        this.afterLogin(ex);
      }
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$router.push({ name: "Film" });
    }
  },
};
</script>
