const _ = require('lodash')

const RouteMixin = {
  computed: {
    currentRoute() {
      return this.$route
    },
    currentRouteName() {
      if (_.has(this.currentRoute, 'meta') && _.has(this.currentRoute['meta'], 'alias')) {
        // this.currentRoute['origin-route-name'] = this.currentRoute.name
        // this.$route["origin-route-name"] = this.currentRoute.name
        return this.currentRoute.meta.alias
      }
      return this.currentRoute.name
    },
    currentOriginRouteName() {
      return this.currentRoute.name
    },

    routeParams() {
      return this.currentRoute['params'] || {}
    },

    querys() {
      return this.currentRoute['query'] || {}
    }
  },
  mounted() {
    //console.log(this.currentRoute)
    // product
    if (this.currentRoute.path.toLowerCase() == '/product') {
      //console.log('route-mixin.js', 'Product route')
      this.$router.push({ name: 'Product', params: { channel: 'letstalk' } })
    }
    // console.log('route-mixin.js',
    //   'this.routeParams',
    //   this.routeParams)
  }
}

module.exports = RouteMixin