<template>
  <div>
    <!--RWD背景圖片 放在<nav>之後-->
    <iNetImageCarousel :api_name="'IndexSliderImage'" />
    <!--/RWD背景圖片 放在<nav>之後-->
    <!--最新消息+會員登入-->
    <span id="news" class="my-anchor"></span>
    <div class="container">
      <!-- 未登入時 -->
      <div class="row" v-if="isLogin == false">
        <!--最新消息-->
        <div class="col-md-7 my-right-line">
          <NewsPost :api_name="'NewsPost'" />
        </div>
        <!--/最新消息-->
        <!--會員登入-->
        <div class="col-md-5 hidden-xs hidden-sm my-left-space">
          <Login />
        </div>
        <!--/會員登入-->
      </div>
      <!-- 登入時 -->
      <div class="row" v-if="isLogin == true">
        <!--最新消息-->
        <div class="col-md-12">
          <NewsPost :api_name="'NewsPost'" />
        </div>
        <!--/最新消息-->
      </div>
    </div>
    <!--/最新消息+會員登入-->
    <!--常見問題-->
    <span id="fqa" class="my-anchor"></span>
    <div class="my-bgcolor">
      <div class="container">
        <div class="row">
          <div class="col-sm-12"><QA :api_name="'QA'" /></div>
        </div>
      </div>
    </div>
    <!--/常見問題-->
    <!--聯絡我們-->
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <br />
          <iNetImageCarousel2 :api_name="'IndexSliderImage2'" />
          <span id="contact" class="my-anchor"></span>
          <h3>聯絡我們</h3>
          <p class="text-center">
            如有任何問題，請留下您的連絡方式，我們會盡快與您連絡，謝謝！
            <span class="text-success">( 請注意：*為必填 )</span>
          </p>
          <br />
        </div>
      </div>
      <div class="row">
        <v-form v-slot="{ handleSubmit }">
          <form
            class="form-horizontal"
            role="form"
            v-on:submit.prevent="handleSubmit(contact_us.sendContactUs)"
          >
            <div class="col-sm-8 col-lg-7 col-lg-offset-1">
              <div class="form-group">
                <label class="col-sm-4 control-label" for="name">
                  <span>*</span>
                  您的姓名
                </label>
                <div class="col-sm-8">
                  <v-validation
                    rules="required|minmax:2,50"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="contact_us['user-name']"
                    />
                    <div class="error-message">{{ errors[0] }}</div>
                  </v-validation>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 control-label" for="email">
                  <span>*</span>
                  電子信箱
                </label>
                <div class="col-sm-8">
                  <v-validation rules="required|email" v-slot="{ errors }">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="contact_us['user-email']"
                    />
                    <div class="error-message">{{ errors[0] }}</div>
                  </v-validation>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 control-label" for="telphone">
                  <span>*</span>
                  聯絡電話
                </label>
                <div class="col-sm-8">
                  <v-validation rules="required" v-slot="{ errors }">
                    <input
                      type="tel"
                      class="form-control"
                      id="telphone"
                      v-model="contact_us['user-phone']"
                    />
                    <div class="error-message">{{ errors[0] }}</div>
                  </v-validation>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 control-label" for="carrier"
                  >使用平台載具</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    v-model="contact_us['user-platform']"
                  >
                    <option>-- 請選擇 --</option>
                    <option value="desktop">電腦</option>
                    <option value="tablet">平板</option>
                    <option value="mobile">手機</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 control-label" for="browser"
                  >使用瀏覽器</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    v-model="contact_us['user-browser']"
                  >
                    <option>-- 請選擇 --</option>
                    <option value="ie">IE</option>
                    <option value="edge">Edge</option>
                    <option value="chrome">Chrome</option>
                    <option value="safari">Safari</option>
                    <option value="firefox">Firefox</option>
                    <option value="other">其他瀏覽器</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 control-label" for="question">
                  <span>*</span>
                  問題類別</label
                >
                <div class="col-sm-8">
                  <v-validation rules="required" v-slot="{ errors }">
                    <select
                      class="form-control"
                      v-model="contact_us['question-type']"
                    >
                      <option>--請選擇--</option>
                      <option value="register">會員註冊</option>
                      <option value="shopping">購物服務</option>
                      <option value="tv">無法收視</option>
                      <option value="other">其他</option>
                    </select>
                    <div class="error-message">{{ errors[0] }}</div>
                  </v-validation>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 control-label" for="content">
                  <span>*</span>
                  問題描述
                </label>
                <div class="col-sm-8">
                  <v-validation rules="required" v-slot="{ errors }">
                    <textarea
                      class="form-control"
                      rows="6"
                      v-model="contact_us['question-info']"
                    ></textarea>
                    <div class="error-message">{{ errors[0] }}</div>
                  </v-validation>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-lg-2">
              <button
                type="submit"
                class="btn btn-success btn-block my-btn-contact"
              >
                送&nbsp;出
              </button>
            </div>
          </form>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NewsPost from "@/components/iNetList/NewsPost";
import QA from "@/components/iNetList/QA";
import iNetContent from "@/components/iNetContent.vue";
import iNetImageCarousel from "@/components/ImageCarousel/iNetImageCarousel.vue";
import iNetImageCarousel2 from "@/components/ImageCarousel/iNetImageCarousel2.vue";
import Login from "@/components/Login.vue";

const ua = require("ua-parser-js");
// const publicIp = require("public-ip");
const api = require("@/api");

export default {
  name: "Index",
  data() {
    return {
      contact_us: {
        "user-name": "",
        "user-email": "",
        "user-phone": "",
        "user-platform": "",
        "user-browser": "",
        "question-type": "",
        "question-info": "",
        sendContactUs: this.sendContactUs,
      },
    };
  },
  components: {
    iNetContent,
    iNetImageCarousel,
    iNetImageCarousel2,
    Login,
    NewsPost,
    QA,
  },
  methods: {
    // triggerLoading() {
    //   this.$emit("loading", true);
    // },

    async sendContactUs() {
      let parseItem = (key, value) => {
        if (key == null || key == undefined) return null;
        if (key == "") return null;

        if (value == null || value == undefined) return null;
        if (value == "") return null;

        if (typeof value == "function") return null;

        let _item = {};
        _item[key] = value;

        return _item;
      };
      let contact = this.contact_us;
      let variables = [];

      Object.entries(contact).map(([key, value]) => {
        let item = parseItem(key, value);
        if (item != null) {
          variables.push(item);
        }
      });

      let payload = {
        //to: contact["user-email"],
        "mail-variables": variables,
      };

      let response = await api.SendContactUs(payload);
      if (response.status_code == 200) {
        this.$swal({
          icon: "success",
          text: "您的問題已經送出，我們會盡快處理 !",
          showConfirmButton: false,
          backdrop: false,
          position: "top-end",
          timer: 6000,
        });
      }
    },
  },
  mounted() {
    let _this = this;

    //this.refreshApp();

    var userAgent = navigator.userAgent;

    // browser, device, os
    var { browser, device, os } = ua(userAgent);

    // let message = `os : ${os.name} ver:${os.version}\r\n`;
    // message += `device : v:${device.vendor}, m:${device.model}, t:${device.type}\r\n`;
    // message += `browser : ${browser.name} major:${browser.major} version:(${browser.version})`;

    // this.$swal({
    //   icon: "success",
    //   text: message,
    //   showConfirmButton: true,
    //   position: "top-end",
    // });

    // var os = ua.getOS();
    // var device = ua.getDevice();
    // var browser = ua.getBrowser();
    if (device !== undefined && device != null) {
      var device_type =
        device.type === undefined ? undefined : device.type.toLowerCase();

      switch (device_type) {
        case "tablet":
        case "mobile":
          this.contact_us["user-platform"] = device_type;
          break;
        default:
          this.contact_us["user-platform"] = "desktop";
          break;
      }
    }

    if (browser !== undefined && browser != null) {
      var browser_name = browser.name.toLowerCase();

      switch (browser_name) {
        case "chrome":
        case "firefox":
        case "safaru":
        case "edge":
          this.contact_us["user-browser"] = browser_name;
          break;
      }
    }

    // console.log(
    //   "ua-parser",
    //   "os :",
    //   os,
    //   ", device :",
    //   device,
    //   ", browser :",
    //   browser
    // );

    // console.log("navigator :", navigator);

    this.contact_us["os"] = `${os.name} ${os.version}`;
    this.contact_us["browser"] = `${browser.name} (${browser.version}})`;
    this.contact_us["device"] = `${device.vendor} ${device.model}`;

    // console.log("networkType(); ", networkType);

    // extIP.get().then(
    //   (ip) => {
    //     console.log(ip);
    //   },
    //   (err) => {
    //     console.error(err);
    //   }
    // );
    // (async () => {
    //   var ip = await publicIp.v4();
    //   //=> '46.5.21.123'
    //   _this.contact_us["user-ip"] = ip;
    //   // console.log(await publicIp.v6());
    //   //=> 'fe80::200:f8ff:fe21:67cf'
    // })();
  },
};
</script>
