<template>
  <div>
    <v-runtime-template
      :template="inlineContent"
      :renderComponent="renderComponent"
      v-style="inlineStyle"
    ></v-runtime-template>
  </div>
</template>
<script>
const _ = require("lodash");
const api = require("@/api");
const config = require("@/config");

//import { WatchMixin } from "@/mixins";
const { WatchMixin, RouteMixin, LoginMixin } = require("@/mixins");

export default {
  data() {
    return {
      content: "",
      customCss: "",
      renderComponent: true,
    };
  },
  props: {
    api_name: String,
    metadata: Object,
    metadata_ready: Boolean,
    isLoadingDone: Boolean,
  },
  methods: {
    // forceUpdate() {
    //   //this.$forceUpdate();
    //   this.renderComponent = false;
    //   // If you like promises better you can
    //   // also use nextTick this way
    //   this.$nextTick().then(() => {
    //     // Add the component back in
    //     this.renderComponent = true;
    //   });
    // },
    async loadFromApi(apiName) {
      //console.log('apiName :', apiName);
      if (apiName && apiName !== "") {
        let url = config["inet-api"][apiName];
        // console.log("apiName", apiName, "url", url);
        let result = await api.GetBoardContent(url);
        if (result && result["iBoard"]) {
          let board = result["iBoard"];
          this.content = board["ContentC"];
          if (board["CustomCSS"]) {
            this.customCss = board["CustomCSS"];
          }
        }

        // console.log('this.isLogin :', this.isLogin)

        this.$forceUpdate();
        // console.log('vue-inet-content-template.js',
        //   'this.currentRouteName',
        //   this.currentRouteName)
      }
    },

    getRefs() {
      return this.$refs;
    },
  },
  computed: {
    inlineStyle() {
      return this.customCss;
    },
    inlineContent() {
      return `<div>${this.content}</div>`;
    },
    getIsLogin() {
      return this.isLogin;
    },
    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
  },
  mounted() {
    let _this = this;

    // console.log('vue-inet-content-template.js',
    //   'mounted()',
    //   'this',
    //   this)

    let checkCondition = function() {
      //return _.isString(_this.api_name) && _this.api_name != "";
      var apiNameReady = _.isString(_this.api_name) && _this.api_name != "";
      var metadataReady =
        _.isBoolean(_this.metadata_ready) && _this.metadata_ready == true;
      if (_this.metadata === undefined) metadataReady = true;

      return apiNameReady && metadataReady;
    };

    // this.loadFromApi(_this.api_name);
    this.watch(checkCondition, 100, 15000)
      .then(function() {
        _this.loadFromApi(_this.api_name);
      })
      .catch(function(ex) {
        console.log("VueInetContentTemplate.vue", "watch #1", "error", ex);
      });
  },
  mixins: [WatchMixin, RouteMixin, LoginMixin],
  watch: {
    currentRouteName: function(value) {
      let _this = this;
      console.log(
        "VueInetContentTemplate.vue",
        "watch",
        "currentRouteName :",
        this.currentRouteName
      );
    },

    getIsLogin: function(value) {
      console.log(
        "VueInetContentTemplate.vue",
        "watch",
        "getIsLogin :",
        this.getIsLogin
      );
    },
  },
};
</script>
