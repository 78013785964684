const jwt = require('jsonwebtoken')

const LoginMixin = {
  data() {
    return {
      tokenDecoded: null,
      decodeError: null,
    }
  },
  computed: {
    currentLoginToken() {
      var value = this.$sessionStorage.get('jwt-token', null);
      var mockValue = this.$sessionStorage.get('mock-jwt-token', null);

      if (mockValue != null) {
        return mockValue;
      }

      if (value != null) {
        return value;
      }
      return this.$localStorage.get('jwt-token', null);
    },
    currentTimestamp() {
      var timestampInMs = new Date().getTime();
      return Math.floor(timestampInMs / 1000);
    },
    currentUserId() {
      if (this.isLogin == false) return null;
      return this.tokenDecoded['uid']
    },
    currentUserName() {
      if (this.isLogin == false) return null;
      return this.tokenDecoded['name'];
    },
    isLogin() {
      var token = this.currentLoginToken;

      if (token == null) {
        return false;
      }

      try {
        // console.log(token.token)
        // console.log(token.secret)
        //this.tokenDecoded = jwt.verify(token.token, token.secret)
        this.tokenDecoded = jwt.decode(token.token)
        //console.log('this.tokenDecoded', this.tokenDecoded)
        // this.tokenDecoded = jwt.verify(token.token, '12345')

        // console.log('login-mixin',
        //   'isLogin',
        //   'jwt.verify :',
        //   //jwt.verify(token.token, token.secret),
        //   this.tokenDecoded,
        //   'jwt.error :',
        //   this.decodeError,
        //   'currentTimestamp :',
        //   this.currentTimestamp
        // );

        return true
      }
      catch (error) {
        console.log(error)
        // err
        this.tokenDecoded = null
        this.decodeError = error

        return false
      }

      // return this.tokenDecoded != null;
    },
    isNeedRefreshToken() {
      if (this.isLogin == false) return false;
      var exp = this.tokenDecoded.exp
      // console.log('login-mixin',
      //   'isNeedRefresh',
      //   'exp :',
      //   exp,
      //   'currentTimestamp',
      //   this.currentTimestamp,
      //   'expired ?',
      //   exp < this.currentTimestamp,
      // )
    }
  },
  methods: {
    getToken: function () {
      try {
        var token = this.currentLoginToken

        return {
          "token": this.isLogin ? token : null,
          "error": null
        }
        // this.tokenDecoded = jwt.verify(token.token, '12345')
      }
      catch (error) {
        return {
          "token": null,
          "error": error
        }
      }
    }
  },
  mounted() {

  },
  watch: {
    isLogin: function (value) {
      // console.log('vue-inet-content-template.js',
      //   'watch',
      //   'isLogin :',
      //   this.isLogin,
      //   'value :',
      //   value
      // )
    }
  }
}

module.exports = LoginMixin