const $ = require('jquery')

const VueRouterLink = {
  install: function (Vue) {
    Vue.directive('link', {
      bind: function (el, binding, vnode) {
        //console.log('VueRouterLink', vnode.context.$router);
        el.addEventListener('click', function ($event) {
          //console.log('VueRouterLink.js', $event)
          //console.log('VueRouterLink.js', binding)
          $event.stopPropagation();
          $event.preventDefault();

          vnode.context.$router.push({ name: binding.value })
        })
      }
    })
  }
}

module.exports = VueRouterLink