<template>
  <div>
    <h3>會員登入</h3>
    <br />
    <v-form v-slot="{ handleSubmit }">
      <form
        class="form-horizontal"
        role="form"
        v-on:submit.prevent="handleSubmit(login)"
      >
        <div class="col-md-8">
          <div class="form-group">
            <v-validation rules="required|minmax:3, 20" v-slot="{ errors }">
              <input
                type="text"
                class="form-control"
                id="id"
                placeholder="帳號"
                v-model="account"
              />
              <span class="error-message">{{ errors[0] }}</span>
            </v-validation>
          </div>
          <div class="form-group">
            <v-validation rules="required" v-slot="{ errors }">
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="密碼"
                v-model="password"
              />
              <span class="error-message">{{ errors[0] }}</span>
            </v-validation>
          </div>
          <div class="form-group" v-if="false">
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="isRemember" />
                記住我
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div class="col-md-12">
              <button
                type="submit"
                class="btn btn-success btn-block my-btn-login"
              >
                登入
              </button>
            </div>
          </div>
        </div>
      </form>
    </v-form>
    <div>
      <p class="text-center login-footer-p">
        <span>|</span> &nbsp;&nbsp;
        <a href="/member-register">註冊新帳號</a> &nbsp;&nbsp;
        <span>|</span> &nbsp;&nbsp;
        <a href="/member-forget">忘記密碼</a> &nbsp;&nbsp; <span>|</span>
      </p>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import _ from "lodash";
import Vue from "vue";
const axios = require("axios");
const api = require("@/api");

export default {
  name: "Login",
  data() {
    return {
      account: "",
      password: "",
      isRemember: false,
    };
  },
  methods: {
    afterLogin(response) {
      this.hideLoading("login");

      if (response) {
        var result = response.result;
        //console.log("MemberLogin", "result : ", result);

        if (response.status_code == 200) {
          var token = result.access_token;
          var secret = result.secret;
          if (this.isRemember == true) {
            this.$localStorage.set("jwt-token", { token, secret });
          } else {
            this.$sessionStorage.set("jwt-token", { token, secret });
          }

          // let mock_jwt = {
          //   token:
          //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJjaGlhb3BhIiwibmFtZSI6IueEpuaYjuWAqyIsImV4cCI6MTYwODA5NTMwMSwibmJmIjoxNjA3OTIyNTAxLCJpYXQiOjE2MDc5MjI1MDEsImlzcyI6ImEyOGJjNzYwLWViZjMtNGFhYi05YTRiLTdkNWM1N2NlNWNiMCIsImF1ZCI6ImNoaWFvcGEifQ.VGAMZ-EIPqyQSP4J1-ei0MmR8u0SOm7tndw15GAa3fM",
          //   secret: "_ks80dHVAfPmHO5ceng9PDZXue2M1jMjY7hYqYf-24s",
          // };
          // this.$sessionStorage.set("mock-jwt-token", mock_jwt);

          //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          //this.$router.push({ name: "Film" });
          //var path = this.$router.resolve({ name: "Film" }).href;

          let _this = this;

          const checkIsLogin = function() {
            //console.log("_this.isLogin", _this.isLogin);
            return _this.isLogin;
          };

          const nextStep = function() {
            _this
              .$swal({
                icon: "success",
                text: "登入成功 !",
                showConfirmButton: false,
                position: "top-end",
                timer: 2000,
              })
              .then(function() {
                var path = _this.$router.resolve({ name: "Film" }).href;
                // _this.$router.push({ name: "Film" });
                location.href = path;
                // _this.$emit("redirectTo", { name: "Film" });
              });
          };

          const check = function() {
            if (checkIsLogin()) {
              return nextStep();
            }

            setTimeout(check, 100);
          };

          //check();
          setTimeout(nextStep, 1000);
        } else {
          if (_.has(result, "data")) {
            let $data = result.data;
            if (_.has($data, "is_not_valid") && $data.is_not_valid == true) {
              // account not valid
              this.$swal({
                icon: "error",
                text:
                  "您的帳號尚未完成驗證，請先依照畫面指示操作，完成驗證程序",
                showConfirmButton: true,
                backdrop: false,
                position: "top-end",
                //timer: 10000,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> 知道了',
              });
              this.$router.push({ name: "MemberRegisterVerify" });
              return;
            }
          }
          let message = "";

          if (_.has(result, "data")) {
            let $data = result.data;

            if (_.has($data, "message")) {
              message = `${$data.message} (${result.result_number})`;
            } else {
              message = `${$data} (${result.result_number})`;
            }
          }

          this.$swal({
            icon: "error",
            text: message,
          });
        }
      }
    },
    async login() {
      //this.$emit("loading", true);
      this.showLoading("login");

      var postdata = {
        account: this.account,
        password: this.password,
      };

      this.showLoading("login");
      var response = {};
      try {
        response = await api.Login(postdata);

        this.hideLoading("login");
      } catch (ex) {
        this.hideLoading("login");

        let message = "";
        let result = {};

        if (_.isObject(ex) && _.has(ex, "result")) {
          result = ex.result;
        }

        if (_.has(result, "data")) {
          let $data = result.data;

          if (_.has($data, "message")) {
            message = `${$data.message} (${result.result_number})`;
          } else {
            message = `${$data} (${result.result_number})`;
          }
        }

        this.$swal({
          icon: "error",
          text: message,
        });

        return;
      }
      this.hideLoading("login");

      this.afterLogin(response);
    },
  },
};
</script>
