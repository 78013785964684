const _ = require('lodash')

const metadataMixin = require('./metadata-mixin')

const ShoppingCartMixin = {
  data() {
    return {

    }
  },
  computed: {
    currentShoppingCart() {
      var cart = this.$sessionStorage.get("shopping-cart", []);

      if (this.getIsObserve(cart)) {
        return this.getObserveValue(cart);
      }
      return cart;
    },
  },
  methods: {
    clearShoppingCart: function () {
      this.$sessionStorage.remove("shopping-cart");
    }
  },
  mixins: [metadataMixin],
  mounted() {

  }
}

module.exports = ShoppingCartMixin