import "@babel/polyfill"
import $ from 'jquery'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// custom global css import
import './scss/main.scss'


// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/core';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// plugin : VeeValidate 3.x
import VeeValidate from './plugins/VeeValidate'
Vue.use(VeeValidate, {
  events: 'change'
})

// webstorage
import StoragePlugin from 'vue-web-storage'
Vue.use(StoragePlugin, {
  prefix: '_digichannel-',
  drivers: ['local', 'session']
})

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
import 'sweetalert2/dist/sweetalert2.min.css';

// plugin : vue-tour
import VueTour from 'vue-tour';
Vue.use(VueTour);
import 'vue-tour/dist/vue-tour.css';

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
Vue.directive('tooltip', VTooltip)

import VueInlineCss from './plugins/VueInlineCss'
Vue.use(VueInlineCss)

import VueBodyClassname from './plugins/VueBodyClassname'
Vue.use(VueBodyClassname)

import VueRouterLink from './plugins/VueRouterLink'
Vue.use(VueRouterLink)

import GlobalRouteMixin from './plugins/RouteMixin'
Vue.use(GlobalRouteMixin)

import GlobalStorage from './plugins/VueGlobalStorage'
Vue.use(GlobalStorage)

Vue.config.productionTip = false

// custom global components
// Vue.component('v-style', {
//   props: ["css"],
//   render: function (createElement) {
//     let watch = function () {
//       console.log('main.js', 'v-style', 'component', this.inlineStyle)
//     }
//     setTimeout(watch, 3000)
//     return createElement('style', this.$slots.default)
//   },
// });

// import ImportJsxTemplate from './jsx-template'
// ImportJsxTemplate(Vue)

import VRuntimeTemplate from 'v-runtime-template'
Vue.component('v-runtime-template', VRuntimeTemplate)

// import { VueiNetContentTemplate } from './components/vue-inet-content-template'
// Vue.component('vue-inet-content', VueiNetContentTemplate)
import VueInetContentTemplate from "./components/VueInetContentTemplate.vue"
Vue.component("vue-inet-content", VueInetContentTemplate)

import SweetModal from 'sweet-modal-vue/src/plugin'
Vue.use(SweetModal)
//console.log(SweetModal)
//Vue.component('SweetModal', SweetModal)

// global mixins
const {
  MetadataMixin,
  LoginMixin,
  ShoppingCartMixin,
  LoadingMixin,
  SweetAlert2Mixin,
  PWAUpdateMixin,
  GlobalMixin,
} = require("./mixins");

Vue.mixin(MetadataMixin)
Vue.mixin(LoginMixin)
Vue.mixin(ShoppingCartMixin)
Vue.mixin(LoadingMixin)
Vue.mixin(SweetAlert2Mixin)
Vue.mixin(PWAUpdateMixin)
Vue.mixin(GlobalMixin)

// global directive
Vue.directive('init', {
  bind: function (el, binding /*, vnode*/) {
    //console.log(binding.value); //# This line is optional, of course.
  }
})

// global filter
const numeral = require('numeral')
Vue.filter("format-dollar", function (value) {
  return numeral(value).format("0,0") // displaying other groupings/separators is possible, look at the docs
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// disable service worker
const emptyFn = () => { };
if (navigator.serviceWorker) {
  navigator.serviceWorker.register = () => new Promise(emptyFn, emptyFn);
}