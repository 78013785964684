import { mapGetters } from 'vuex';
<template>
  <div class="container my-container">
    <vue-inet-content
      :api_name="'Film-Month-List'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
const moment = require("moment");

const api = require("@/api");

const { LoadingMixin } = require("@/mixins");

export default {
  data() {
    return {
      metadata: {
        // start_year: 0,
        // end_year: 0,
        // start_month: 0,
        // end_month: 0,
        // months: [],
        years: [],
        getMonth: this.getMonth,
        getIsAvailable: this.getIsAvailable,
        getLink: this.getLink,
        channel: this.channel,
      },
      metadata_ready: false,
      months: [],
    };
  },
  //mixins: [LoadingMixin],
  methods: {
    getMonth(year, month) {
      var index = _.findIndex(this.months, { year: year, month: month });
      // console.log(
      //   "getMonth ",
      //   "year :",
      //   year,
      //   ", month :",
      //   month,
      //   ", index :",
      //   index,
      //   ", this.months :",
      //   this.months
      // );
      if (index == -1) return { year, month, status: "none" };

      return this.months[index];
    },
    getLink(year, month) {
      var month = this.getMonth(year, month);
      if (month == null) return "javascript:void(0);";

      if (month.status != "available") return "javascript:void(0);";

      return `/film/${this.channel}/${month.year}/${month.month}`;
    },
    getIsAvailable(year, month) {
      //var index = _.findIndex(this.months, { year: year, month: month });
      var month = this.getMonth(year, month);
      if (month == null) return false;

      //console.log(month, month.is_available);
      return month.is_available;
    },
  },
  computed: {
    ...mapGetters(["OrderMonths"]),

    channel() {
      if (_.has(this.routeParams, "channel")) {
        var channel = this.routeParams["channel"].toLowerCase();
        switch (channel) {
          case "letstalk":
            return channel;
          case "studioclassroom":
            return channel;
        }
      }
      return "";
    },
  },
  mounted() {
    let _this = this;

    if (this.isLogin == false) {
      this.$router.push({ name: "MemberLogin" });
      return;
    }

    let payload = {};
    this.showLoading("get-course-list");
    api.GetCourseList(payload, this.channel).then((response) => {
      this.hideLoading("get-course-list");
      if (response.status_code == 200) {
        var $result = response.result;

        var currentYear = parseInt(moment().format("YYYY"));
        var currentMonth = parseInt(moment().format("M"));

        var start_year = currentYear - 1;
        var start_month = 1;
        var end_year = currentYear + 1;
        var end_month = 12;

        var years = [];
        for (var i = end_year; i >= start_year; i--) {
          years.push(i);
        }
        _this.metadata.years = this.getObserveValue(years);

        var _months = [];
        $result.months.forEach(function(month) {
          let $index = years.indexOf(month.year);

          if ($index == -1) return;

          // if (month.year <= currentYear) {
          //   if (month.year == currentYear && month.month <= currentMonth) {
          //     _months.push(month);
          //   } else if (month.year < currentYear) {
          //     _months.push(month);
          //   }
          // }
          _months.push(month);
        });

        // _this.metadata.months = _months;
        _this.months = _months;

        _this.metadata.channel = this.channel;

        // _this.metadata.start_year = start_year;
        // _this.metadata.start_month = start_month;
        // _this.metadata.end_year = end_year;
        // _this.metadata.end_month = end_month;
        _this.metadata_ready = true;
      }
    });
  },
};
</script>
