<template>
  <div class="container my-container" v-body-classname-remove="'primary-body'">
    <!-- /film?mock_key=0378eaec-1049-404d-bd9c-5aa5cbc64436&mock_man_no=0531 -->
    <!-- /film?mock_key=0378eaec-1049-404d-bd9c-5aa5cbc64436&mock_man_no=oxox -->
    <vue-inet-content
      :api_name="'Film-Channel-Switch'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

const _ = require("lodash");

const config = require("@/config");
const api = require("@/api");

export default {
  name: "Film",
  data() {
    return {
      metadata: {
        is_login: this.isLogin,
      },
      metadata_ready: false,
    };
  },
  methods: {
    ...mapActions(["setOrderMonths"]),
  },
  created() {
    //console.log("Film.vue", "created()", "isLogin", this.isLogin);
  },
  mounted() {
    //console.log("Film.vue", "mounted()", "isLogin", this.isLogin);
    if (this.isLogin == false) {
      this.$router.push({ name: "MemberLogin" });
      return;
    }

    let payload = {};

    if (_.has(this.querys, "mock_key") && _.has(this.querys, "mock_man_no")) {
      payload = {
        "mock-key": this.querys.mock_key,
        mock_man_no: this.querys.mock_man_no,
      };
    }

    this.showLoading("get-course-list");
    api
      .GetCourseList(payload)
      .then((response) => {
        this.hideLoading("get-course-list");

        if (response.status_code == 200) {
          var $result = response.result;

          this.setOrderMonths($result.months);

          if ($result.length == 0 || $result.channels.length == 0) {
            // has no any channels
            this.$router.push({
              name: "Product",
              params: { channel: config["default-channel"] },
            });
            this.metadata_ready = true;
            return;
          } else if ($result.channels.length == 1) {
            this.$router.push({
              name: "Film2",
              params: { channel: $result.channels[0] },
            });
            this.metadata_ready = true;
            return;
          }

          this.metadata_ready = true;
        }
      })
      .catch((ex) => {
        if (ex == "Network Error") {
          this.showBackendError();
        }
      });
  },
  updated() {
    //console.log("Film.vue", "updated()", "isLogin", this.isLogin);
  },
};
</script>
