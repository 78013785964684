module.exports = {
  IsLoading(state) {
    return state.isloading
  },

  ShoppingCart(state) {
    return state.shoppingCart
  },

  RegisterEmail(state) {
    return state.registerEmail
  },

  OrderMonths(state) {
    return state.orderMonths
  }
}