<template>
  <div class="container my-container" v-body-classname-remove="'primary-body'">
    <vue-inet-content
      :api_name="'Member-Register-Verify-Email'"
      :metadata="metadata"
      :metadata_ready="metadata_ready"
    />
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

const api = require("@/api");

export default {
  name: "MemberRegisterVerify",
  data() {
    return {
      metadata: {
        email: "",
        identifier: "",
        verify_code: "",
        verify_sended: false,
        show_verify_only: false,
        next: this.next,
      },
      metadata_ready: true,
    };
  },

  computed: {
    ...mapGetters(["RegisterEmail"]),
  },

  methods: {
    ...mapActions(["setRegisterEmail"]),

    verifyEmail(payload) {
      let _this = this;

      api
        .VerifyEmail(payload)
        .then(function(result) {
          //debugger;
          if (result.status_code == 202) {
            _this.setRegisterEmail("");

            let $result = result.result;

            _this.$swal({
              icon: "success",
              text: $result.data,
              showConfirmButton: false,
              backdrop: false,
              position: "top-end",
              //timer: 8000,
            });

            _this.$router.push({ name: "MemberLogin" });
          } else {
            let $result = result.result;

            let message = `${$result.data} (${$result.result_number})`;

            _this
              .$swal({
                icon: "error",
                text: message,
              })
              .then(function() {
                if (
                  $result.result_number == 0 ||
                  $result.result_number == -1001300
                ) {
                  _this.$router.push({ name: "MemberLogin" });
                } else if ($result.result_number == -1001301) {
                  //_this.$router.push({ name: "MemberRegisterVerify" });
                  var path = _this.$router.resolve({
                    name: "MemberRegisterVerify",
                  }).href;
                  //location.href = path;
                  _this.$emit("redirectTo", {
                    name: "MemberRegisterVerify",
                  });
                }
              });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async next() {
      if (
        this.currentMetadata.email == null ||
        this.currentMetadata.email.length == 0
      ) {
        return;
      }

      if (this.currentMetadata.verify_sended == true) {
        // do code verify
        if (this.currentMetadata.identifier != "") {
          let payload = {
            identifier: this.currentMetadata.identifier,
            code: this.currentMetadata.verify_code,
          };

          this.verifyEmail(payload);
        }
        return;
      }

      let postdata = {
        email: this.metadata.email,
      };

      this.showLoading("send-verify");
      let result = await api.SendVerify(postdata);
      this.hideLoading("send-verify");

      if (result.status_code >= 400) {
        let $result = result.result;

        let message = $result.data;

        this.$swal({
          icon: "error",
          text: message,
        });

        return;
      }

      if (result.status_code == 200) {
        let $result = result.result;

        this.metadata.verify_sended = true;

        this.metadata.identifier = $result.data.identifier;

        this.$swal({
          icon: "success",
          text:
            "系統已經將驗證郵件發送至您的電子郵件信箱，請查看信件內容，並依照指示完成操作",
          showConfirmButton: true,
          backdrop: false,
          position: "top-end",
          //timer: 8000,
        });
      }
    },
  },

  async mounted() {
    if (_.has(this.querys, "i") && _.has(this.querys, "code")) {
      // comes from url in email
      let _this = this;
      //debugger;
      this.metadata.show_verify_only = true;

      //console.log("MemberRegisterVerify", "Querys : ", this.querys);
      let payload = {
        identifier: this.querys.i,
        code: this.querys.code,
      };

      this.verifyEmail(payload);

      return;
    }

    // not come from email, usually is after register done.
    if (this.RegisterEmail == null || this.RegisterEmail.length == 0) {
      this.$swal({
        icon: "warning",
        text:
          "請輸入會員資料中使用的電子郵件信箱位址後，點選送出，並依照信件提示完成驗證流程",
        showConfirmButton: true,
        backdrop: false,
        position: "top-end",
        //timer: 8000,
      });

      return;
    }
    this.metadata.email = this.RegisterEmail;
    this.next();
  },
};
</script>
