module.exports = {
  SetIsLoading(state, { isloading }) {
    state.isloading = isloading
  },

  AddToShoppingCart(state, { product }) {
    state.shoppingCart.push(product)
  },

  SetRegisterEmail(state, { email }) {
    state.registerEmail = email
  },

  SetOrderMonths(state, { months }) {
    state.orderMonths = months
  },
}