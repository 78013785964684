import "@babel/polyfill"
import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'

require("@babel/polyfill");

const gt = require("just-get-json");
const config = require("@/config");

// remote version :
const options = {
  "config-online-host": "https://digichannel.go2school.com.tw",
  "config-online-url": "/config/route.json?_=6e81dd8e-0e39-4277-8a46-24bdf4183ffe",
};
var request = options["config-online-host"] + options["config-online-url"];
//var request = config["config-online-url"];
var routes = gt(request);
// local version :
//const routes = require("../config/route.json");

import '../views/index'
const views = require("../views/index");
// import Index from '../views/Index.vue'
// import MemberLogin from '../views/MemberLogin.vue'
// import MemberLogout from '../views/MemberLogout.vue'
// import MemberRegister from '../views/MemberRegister.vue'
// import MemberRegister2 from '../views/MemberRegister2.vue'
// import MemberRegisterVerify from '../views/MemberRegisterVerify.vue'
// import MemberRegisterOK from '../views/MemberRegisterOK.vue'
// import MemberForget from '../views/MemberForget.vue'
// import MemberForgetOK from '../views/MemberForgetOK.vue'
// import MemberCenter from '../views/MemberCenter.vue'
// import Product from '../views/Product.vue'
// import TryFilm from '../views/TryFilm.vue'
// import Shop from '../views/Shop.vue'
// import Shop2 from '../views/Shop2.vue'
// import Shop3 from '../views/Shop3.vue'
// import Film from '../views/Film.vue'
// import Film2 from '../views/Film2.vue'
// import Film3 from '../views/Film3.vue'
// import Film4 from '../views/Film4.vue'

Vue.use(VueRouter)

routes.forEach((route) => {
  if (_.has(views, route.component)) {
    route.component = views[route.component];
  }
})
// const routes = [
//   {
//     path: '/',
//     name: 'Index',
//     component: Index,
//   },
//   {
//     path: '/member-login',
//     name: 'MemberLogin',
//     component: MemberLogin,
//     meta: { alias: 'Login' }
//   },
//   {
//     path: '/logout',
//     name: 'MemberLogout',
//     component: MemberLogout,
//   },
//   {
//     path: '/member-register',
//     name: 'MemberRegister',
//     component: MemberRegister,
//     meta: { alias: 'Login' }
//   },
//   {
//     path: '/member-register-form',
//     name: 'MemberRegisterForm',
//     component: MemberRegister2,
//     meta: { alias: 'Login' }
//   },
//   {
//     path: '/member-register-ok',
//     name: 'MemberRegisterOK',
//     component: MemberRegisterOK,
//     meta: { alias: 'Login' }
//   },
//   {
//     path: '/member-register-verify',
//     name: 'MemberRegisterVerify',
//     component: MemberRegisterVerify,
//     meta: { alias: 'Member' }
//   },
//   {
//     path: '/member-forget',
//     name: 'MemberForget',
//     component: MemberForget,
//   },
//   {
//     path: '/member-forget-ok',
//     name: 'MemberForgetOK',
//     component: MemberForgetOK,
//   },
//   {
//     path: '/member-reset',
//     name: 'MemberReset',
//     component: MemberForget,
//   },
//   {
//     path: '/member-reset-ok',
//     name: 'MemberResetOK',
//     component: MemberForgetOK,
//   },
//   {
//     path: '/member-center',
//     name: 'MemberCenter',
//     component: MemberCenter,
//     meta: { alias: 'Member' }
//   },
//   {
//     path: '/product/:channel',
//     name: 'Product',
//     component: Product,
//   },
//   {
//     path: '/free-film/:channel',
//     name: 'FreeFilmChannel',
//     component: TryFilm,
//     meta: { alias: 'FreeFilm' }
//   },
//   {
//     path: '/shop',
//     name: 'Shop',
//     component: Shop,
//   },
//   {
//     path: '/shop2',
//     name: 'Shop2',
//     component: Shop2,
//     meta: { alias: 'Shop' },
//   },
//   {
//     path: '/shop3',
//     name: 'Shop3',
//     component: Shop3,
//     meta: { alias: 'Shop' },
//   },
//   {
//     path: '/film',
//     name: 'Film',
//     component: Film,
//   },
//   {
//     path: '/film/:channel',
//     name: 'Film2',
//     component: Film2,
//     meta: { alias: 'Film' },
//   },
//   {
//     path: '/film/:channel/:year/:month',
//     name: 'Film3',
//     component: Film3,
//     meta: { alias: 'Film' },
//   },
//   {
//     path: '/film4/:channel/:year/:month/:day',
//     name: 'Film4',
//     component: Film4,
//     meta: { alias: 'Film' },
//   },
// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
